import React, { Component } from 'react';
import Select from 'react-select';


export default class Dropdown extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props
      };
   }

   componentWillReceiveProps(newProps) {
      if(this.props !== newProps){
         this.props = newProps;
         this.setState({
            ...newProps
         });
      }
   }

   render(){
      var options = this.state.options.map(x => {
         return {
            value: x,
            label: x
         };
      });
      return (
         <div>
            <div style={{fontSize: '1.3em', marginTop: '10px'}}>{this.state.mandatory ? "* " : ""}{this.state.placeholder}</div>
            <Select 
            name=""
            options={options}
            searchable={false}
            clearable={false}
            value={this.state.value ? this.state.value : ''}
            placeholder={this.state.placeholder}
            onChange={(value) => {
               this.setState({
                  value : value
               });
            }}
            />
         </div>
      );
   }
}
