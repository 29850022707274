var isArray = (array) => {
   for(var i=0; i<array.length; i++){
      if(Array.isArray(array[i])) return true;
   }
   return false;
}

var flatten = (arr) => {
   return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
   }, []);
}

var camelize = (str) => {
    if(str){
   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) 
         return ""; // or if (/\s+/.test(match)) for white spaces
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
   });
    }else{
        return null;
    }
}

var isEmpty = (obj) => {
   for(var key in obj) {
      if(obj.hasOwnProperty(key))
         return false;
   }
   return true;
}

module.exports = {
   isArray: isArray,
   flatten: flatten,
   isEmpty : isEmpty,
   camelize : camelize
}
