import React, { Component } from "react";

import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  Glyphicon,
} from "react-bootstrap";
import Select from "react-select";
var conf = require("../../../../conf.js");

export default class Applicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      applicants: [],
      contracts: [],
      modalShow: false,
      name: "",
			email: "",
			candidateid: "",
      contract: undefined,
    };
  }

  componentWillMount() {
    this.refreshApplicants();
    this.refreshContracts();
  }

  refreshContracts() {
    fetch(conf.baseURL + "/contracts", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.setState({
          contracts: r,
        });
      });
  }

  refreshApplicants() {
    fetch(conf.baseURL + "/applicants", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.setState({
          applicants: r,
        });
      });
  }

  _renderApplicants() {
    if (this.state.applicants.length == 0) {
      return (
        <h4 style={{ textAlign: "left", fontStyle: "italic", width: "100%" }}>
          No current applicants
        </h4>
      );
    }
    return this.state.applicants.map(x => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "50x",
          }}
        >
          <Button
            onClick={() => {
              this.props.onFocus(x.applicant_id);
            }}
            style={{
              display: "flex",
              flex: 1,
              marginTop: "5px",
              textAlign: "left",
              paddingLeft: "20px",
              height: "50px",
              fontSize: "1.6em",
            }}
          >
            {x.name}{" "}
            {x.status === "Completed" ? <span role="img">️ ✔</span> : ""}
          </Button>
          <Button
            onClick={() => {
              if (window.confirm(`Delete ${x.name}?`)) {
                fetch(conf.baseURL + `/applicants/${x.applicant_id}/remove`, {
                  method: "GET",
                  headers: {
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                  credentials: "include",
                })
                  .then(r => {
                    return r.json();
                  })
                  .then(r => {
                    return r;
                  })
                  .then(r => {
                    this.refreshApplicants();
                  });
              }
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5px",
              width: "50px",
              marginTop: "5px",
              textAlign: "left",
              height: "50px",
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </div>
      );
    });
  }

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSelectChange = e => {
    if (e) this.setState({ contract: e.value });
  };

  createApplicant() {
    var applicant = {
			name: this.state.name,
			candidateid: this.state.candidateid,
      email: this.state.email,
      contract_id: this.state.contract.contract_id,
      status: "Not Completed",
    };
    console.log(applicant);
    fetch(conf.baseURL + "/applicants", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        applicant: applicant,
      }),
    })
      .then(r => {
        return r;
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.refreshApplicants();
      });
  }

  onSubmit = e => {
    console.log(this.state);
    this.createApplicant();
    if (e) e.preventDefault();
    this.setState({
			modalShow: false,
			candidateid: "",
      name: "",
      email: "",
      contract: "",
    });
  };

  _renderModal() {
    var value = this.state.contract
      ? { value: this.state.contract, label: this.state.contract.contract_name }
      : { value: {}, label: "" };
    return (
      <Modal
        show={this.state.modalShow}
        onKeyPress={e => {
          if (e.charCode == 13 && this.state.modalShow) {
            e.preventDefault();
            this.onSubmit();
          }
        }}
      >
        <Modal.Header>
          <Modal.Title>Send New Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>
                Applicant Name
              </ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>
                Resman CandidateID
              </ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="candidateid"
                value={this.state.candidateid}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel style={{ marginTop: "5px" }}>
                Applicant Email
              </ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel style={{ marginTop: "5px" }}>
                Contract Type
              </ControlLabel>
              <Select
                searchable={false}
                clearable={false}
                style={{ marginTop: "4px" }}
                name="contract"
                value={value}
                onChange={this.onSelectChange}
                options={this.state.contracts.map(x => {
                  return { value: x, label: x.contract_name };
                })}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              this.setState({
                modalShow: false,
                name: "",
                email: "",
                contract: {},
              });
            }}
          >
            Close
          </Button>
          <Button onClick={this.onSubmit} bsStyle="primary">
            Send Application
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "90%",
            flexDirection: "column",
            minHeight: "min-content",
          }}
        >
          {this._renderModal()}
          <h2 style={{ textAlign: "left", width: "100%" }}>Applicants</h2>
          {this._renderApplicants()}
          <Button
            bsStyle="primary"
            style={{
              fontSize: "1.2em",
              width: "190px",
              marginTop: "20px",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              this.setState({ modalShow: true });
            }}
          >
            Send new application
          </Button>
        </div>
      </div>
    );
  }
}
