import React, { Component } from 'react'
import { Button, Glyphicon, FormControl } from 'react-bootstrap'
import EditorModal from './editorModal'; 
import FSelect from './dummycomponents/fselect';
import FList from './dummycomponents/flist';
import List from './dummycomponents/list';
import Input from './dummycomponents/input';
import Section from './dummycomponents/section';
import FileChooser from './dummycomponents/filechooser';
import JsonBuilder from '../admin-model-viewer/jsonBuilder';
import DisplayKeysModal from './displayKeysModal';
import Paragraph from './dummycomponents/paragraph.js';
import Checkbox from './dummycomponents/checkbox.js';
import Signature from './dummycomponents/signature.js';
import Dropdown from './dummycomponents/dropdown.js';

const util = require('../../utils');
const uuid = require('uuid');


export default class FormCreator extends Component{
   constructor(props){
      super(props);
      this.state = {
         ...props,
         json : this.createJSON([], '', '', []),
         displayKeys : [],
         canAddSection : true,
         componentIndex : -1,
         sections: [],
         showModal: false,
         editingElement : false,
         editingComponentIndex : -1,
         editingElementIndex : -1,
         editingTitle : false,
         title : "",
         _id : ''
      };
   }
   

   componentWillReceiveProps(newProps){
      if(!newProps.data){
         this.setState({
            ...newProps,
            json : this.createJSON([], '', ''),
            canAddSection : true,
            componentIndex : -1,
            sections: [],
            showModal: false,
            editingTitle : false,
            title : "",
            _id : '' 
         }); 
      }else{
         if(this.props !== newProps){
            this.setState({
               ...newProps
            });
            if(newProps.data){
               if(!util.isEmpty(newProps.data) && newProps.data !== this.props.data){
                  this.parseComponentsFromJSON(newProps.data);
               }
            }
         }
      }
   }

   reset(){
      this.setState({
         json : this.createJSON([], '', ''),
         canAddSection : true,
         componentIndex : -1,
         sections: [],
         showModal: false,
         editingTitle : false,
         title : "",
         _id : ''
      }); 
   }

   parseSection(k){
      var section = [];
      k.map((y, j) => {
         if(y.type == "FSELECT" || y.type == "FLIST" || y.type == "LIST" || y.type == "PARAGRAPH" || y.type == "DROPDOWN"){
            section.push({
               type : y.type,
               label : y.label,
               id: y.id,
               "meta-type" : y["meta-type"]
            });
         }else{
            section.push({
               type : y.type,
               placeholder : y.label,
               id: y.id
            });
         }   
      });
      return section;
   }

   parseItem(k){
      var data;
      if(k.type == "FSELECT" || k.type == "FLIST" || k.type == "LIST" || k.type == "PARAGRAPH" || k.type == "DROPDOWN"){
         data = {
            type : k.type,
            label : k.label,
            id: k.id,
            resmanMapping: k.resmanMapping,
            mandatory: k.mandatory,
            "meta-type" : k["meta-type"]
         };
      }else{
         data = {
            type : k.type,
            placeholder : k.label,
            resmanMapping: k.resmanMapping,
            mandatory: k.mandatory,
            id: k.id
         };
      }
      return data;
   }

   parseComponentsFromJSON(JSONData){
      var curComp = [];
      JSONData.model.map((x, i) => {
         var data = [];
         x.map((k,l) => {
            if(l == 0)
               data.push({value : JSONData.model[i][0].title});
            else
               if(k instanceof Array){
                  data.push({
                     type : "SECTION",
                     placeholder : "",
                     elements : this.parseSection(k)
                  });
               }else{
                  var item = this.parseItem(k);
                  data.push(item);
               }
         });
         curComp.push(data);
      });
      this.setState({
         sections : curComp,
         title : JSONData.name,
         _id : JSONData._id,
         displayKeys : JSONData.display_keys,
         json : this.createJSON(curComp, JSONData.name, JSONData._id, JSONData.display_keys)
      });
   }

   componentWillMount(){
      if(this.props.data){
         //Create sections from JSON:
         //Basically just parse the information into my retarded methods
         this.setState({
            displayKeys : this.props.data.display_keys
         });
         this.parseComponentsFromJSON(this.props.data);
      }
   }


   removeElement(componentIndex, elementIndex){
      var sections = this.state.sections;
      sections[componentIndex].splice(elementIndex, 1);
      var json = this.createJSON(sections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : []); 
      this.setState({
         json : json 
      });
      this.parseComponentsFromJSON(json);
   }

   array_move(arr, old_index, new_index) {
      while (old_index < 0) {
         old_index += arr.length;
      }
      while (new_index < 0) {
         new_index += arr.length;
      }
      if (new_index >= arr.length) {
         var k = new_index - arr.length + 1;
         while (k--) {
            arr.push(undefined);
         }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
   };

   moveElementUp(componentIndex, elementIndex) {
      let sections = this.state.sections;
      sections[componentIndex] = this.array_move(sections[componentIndex], elementIndex, elementIndex-1);
      let json = this.createJSON(sections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : []); 
      this.setState({
         json : json 
      });
      this.parseComponentsFromJSON(json);
   }

   moveElementDown(componentIndex, elementIndex) {
      let sections = this.state.sections;
      sections[componentIndex] = this.array_move(sections[componentIndex], elementIndex, elementIndex+1);
      let json = this.createJSON(sections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : []); 
      this.setState({
         json : json 
      });
      this.parseComponentsFromJSON(json);
   }

   _renderEditGlyph(ci, ei, y) {
      return (
         <Glyphicon 
         glyph="pencil" 
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '17px' }}
         onClick={() => {this.setState({editingElement : true, editingComponentIndex : ci, editingElementIndex : ei, showModal : true})}}></Glyphicon> 
      ); 
   }

   _renderRemoveGlyph(ci, ei, y) {
      return (
         <Glyphicon 
         glyph="remove" 
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '0' }}
         onClick={this.removeElement.bind(this, ci, ei)}></Glyphicon>
      );
   }

   _renderMoveUpGlyph(ci, ei, y) {
      return (
         <Glyphicon 
         glyph="arrow-up" 
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '34px' }}
         onClick={this.moveElementUp.bind(this, ci, ei)}></Glyphicon>
      );
   }

   _renderMoveDownGlyph(ci, ei, y) {
      return (
         <Glyphicon 
         glyph="arrow-down" 
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '51px' }}
         onClick={this.moveElementDown.bind(this, ci, ei)}></Glyphicon>
      );
   }   

   _renderSectionElements(x, componentIndex){
      return x.map((y, elementIndex) => {
         switch(y.type) {
            case "FSELECT":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <FSelect placeholder={y.placeholder}/>
                  </div>
               );
            case "FLIST":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <FList/>
                  </div>
               );
            case "LIST":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <List style={{paddingTop : '15px'}} label={this.state.json.model[componentIndex][elementIndex].label} 
                           data={this.state.json.model[componentIndex][elementIndex]["meta-type"]}/>
                  </div>
               );
            case "FILECHOOSER":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <FileChooser title={y.placeholder} style={{paddingTop : '15px'}}/>
                  </div>
               );
            case "PARAGRAPH":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <Paragraph paragraph={y["meta-type"].paragraph} style={{paddingTop : '15px'}}/>
                  </div>
               );
				case "CHECKBOX":
               console.log("Checkbox debug:", y);
					return(
						<div style={{position: 'relative', paddingTop: '15px', marginTop: '5px'}}>
							{this._renderEditGlyph(componentIndex, elementIndex, y)}
							{this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
							<Checkbox style={{paddingTop: '15px'}} label={y.placeholder}/>
						</div>
					);
            case "SIGNATURE":
               return (
                  <div style={{position: 'relative', paddingTop: '15px', marginTop: '5px'}}>
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <Signature placeholder={y.placeholder}/>
                  </div> 
               );
            case "DROPDOWN":
               return (
                  <div style={{position: 'relative', paddingTop: '15px', marginTop: '5px'}}>
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <Dropdown placeholder={y.label} options={y["meta-type"].options}/>
                  </div> 
               );
            case "TEXT":
            case "DATE":
            case "NUMBER":
            case "TEXTAREA":
               return (
                  <div style={{position : 'relative', paddingTop : '15px', marginTop : '5px'}}> 
                     {this._renderEditGlyph(componentIndex, elementIndex, y)}
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveDownGlyph(componentIndex, elementIndex, y)}
                     {this._renderMoveUpGlyph(componentIndex, elementIndex, y)}
                     <Input  type={y.type} placeholder={y.placeholder}/>
                  </div>
               );
            case 'SECTION':
               return (
                  <div style={{position : 'relative', display : 'flex', flex : 1, paddingTop : '15px'}}>
                     {this._renderRemoveGlyph(componentIndex, elementIndex, y)}
                     <Section 
                     getModels={this.props.getModels} 
                     componentIndex={componentIndex} 
                     elementIndex={elementIndex} 
                     updateElements={this.onSubSectionChanged.bind(this)} 
                     elements={this.state.sections[componentIndex][elementIndex].elements}/>
                  </div>
               );
         }
         return(<div/>);
      });
   }

   generateSectionJSON(componentIndex, elementIndex, sections){
      return sections[componentIndex][elementIndex].elements.map(y => {
         switch(y.type){
            case "FSELECT":
            case "FLIST":
            case "LIST":
            case "PARAGRAPH":
            case "DROPDOWN":
               return {
                  type : y.type,
                  label : y.label,
                  id : y.id,
                  "meta-type" : y["meta-type"] 
               };
            case "DATE":
            case "TEXT":
            case "FILECHOOSER":
            case "NUMBER":
            case "TEXTAREA":
				case "CHECKBOX":
            case "SIGNATURE":
               return {
                  label : y.placeholder,
                  type : y.type,
                  id : y.id 
               };
         }
      });
   }

   createJSON(sections, title, id, displayKeys){
      var dispKeys = [];
      if(this.state)
         dispKeys = this.state.displayKeys ? this.state.displayKeys : [];
      var json = {
         _id : id ? id : '',
         name : title ? title : '',
         display_keys : [], 
         model : []
      };
      json.display_keys = displayKeys ? displayKeys : dispKeys;
      json.model = sections.map((x, i) => {
         return x.map((y, j) => {
            if(!y) return null;
            if(j == 0)
               return {title : sections[i][j].value};
            else
               switch(y.type){
                  case "FSELECT":
                  case "FLIST":
                  case "LIST":
                  case "PARAGRAPH":
                  case "DROPDOWN":
                     return {
                        type : y.type,
                        label : y.label,
                        id : y.id,
                        resmanMapping: y.resmanMapping,
                        mandatory: y.mandatory,
                        "meta-type" : y["meta-type"],
                     };
                  case "DATE":
                  case "TEXT":
                  case "FILECHOOSER":
                  case "NUMBER":
                  case "TEXTAREA":
						case "CHECKBOX":
                  case "SIGNATURE":
                     return {
                        label : y.placeholder,
                        type : y.type,
                        resmanMapping: y.resmanMapping,
                        mandatory: y.mandatory,
                        id : y.id 
                     }; 
                  case 'SECTION':
                     return this.generateSectionJSON(i, j, sections);               
               }
         });   
      });
      return json;
   }

   

   isMulti(section){
      var multi = false;
      section.map((x) => {
         if(x.type == "SECTION") multi = true;
      });
      return multi;
   }

   removeSection(componentIndex){
      var sections = this.state.sections;
      sections.splice(componentIndex, 1);
      this.setState({
         sections : sections,
         json : this.createJSON(sections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : [])
      });
   }

   _renderSections(){
      return this.state.sections.map((x, i) => {
         return (
            <div style={{display : 'flex', flexDirection : 'column', paddingTop : '12px', position : 'relative'}}>
            <Glyphicon
            glyph="remove"
            style={{fontSize : '12px', position : 'absolute', top : '0', right : '0' }}
            onClick={this.removeSection.bind(this, i)}></Glyphicon>
            <FormControl
            style={{padding : '25px 0 25px 5px', margin : '10px 0 10px 0', fontSize : '32px'}}
            value={this.state.sections[i][0].value}
            onChange={(e) => {
               var s = this.state.sections;
               s[i][0].value = e.target.value;
               this.setState({
                  sections : s,
                  json : this.createJSON(s, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : [])
               });
            }}
            autoFocus={true}
            type="text"
            placeholder="Enter section title!"
            />
            <div style={{
               width : '100%', 
               display : 'flex',
               flex: 1,
               border : '1px solid #ccc', 
               minHeight : '50px', 
               margin : '10px 0 10px 0', 
               padding : '10px', 
               borderRadius : '5px',
               flexDirection : (this.isMulti(x)) ? 'row' :'column'}}>
            {this._renderSectionElements(x, i)}
            </div>
            <footer style={{display : 'flex', justifyContent : 'flex-end'}}>
            <Button bsStyle="info" onClick={() => {
               var addSection = true;
               this.state.sections[i].map((j, index) => {
                  if(index > 0 && j.type != 'SECTION')
                     addSection = false;
               });
               this.setState({showModal : true, componentIndex : i, canAddSection : addSection})
            }} 
            style={{width : '60px', margin : '10px', alignSelf : 'flex-end'}}>Add...</Button>
            </footer>
            </div>
         );      
      }); 
   }

   onAddSection(){
      var currentSections = this.state.sections;
      var ns = [{value : ''}];
      currentSections.push(ns);
      this.setState({
         sections : currentSections,
         json : this.createJSON(currentSections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : [], this.state.displayKeys ? this.state.displayKeys : [])
      });
   }


   onModalClose(){
      this.setState({
         showModal : false,
         editingElement : false
      });
   }


   /*Callback for section elements*/
   onSubSectionChanged(componentIndex, elementIndex, elements){
      if(this.state.sections[componentIndex][elementIndex].type == "SECTION"){
         var sections = this.state.sections;
         sections[componentIndex][elementIndex].elements = elements;
         console.log("Sub section changed:", elements);
         this.setState({
            sections : sections,
            json : this.createJSON(sections, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : [])
         });
      }
   }


   hasSection(){
      if(this.state.sections[this.state.componentIndex]){
         return this.isMulti(this.state.sections[this.state.componentIndex]); 
      }else{
         return false;
      }
   }

   componentModalCallback(id, type, placeholder, editing){
      var curComp = this.state.sections;
      if(editing){
         let oldElement = curComp[this.state.editingComponentIndex][this.state.editingElementIndex];
         if(type.label == "FSELECT" || type.label == "FLIST" || type.label == "LIST" || type.label == "PARAGRAPH" || type.label == "DROPDOWN"){
            curComp[this.state.editingComponentIndex][this.state.editingElementIndex] = {
               type : type.label,
               label : placeholder,
               id: id || uuid.v4(),
               //id: oldElement.id ? oldElement.id : uuid.v4(),
               resmanMapping: type.resmanMapping ? type.resmanMapping : '',
               mandatory: type.mandatory,
               "meta-type" : type.meta
            };
         }else{
            curComp[this.state.editingComponentIndex][this.state.editingElementIndex] = {
               type : type.label,
               placeholder : placeholder,
               resmanMapping: type.resmanMapping ? type.resmanMapping : '',
               mandatory: type.mandatory,
               id: id || uuid.v4(),
               //id: oldElement.id ? oldElement.id : uuid.v4(),
            };
         } 
      }else{
         if(type.label == "SECTION"){
            curComp[this.state.componentIndex].push({
               type : type.label,
               placeholder : placeholder,
               elements : []
            }); 
         }else if(type.label == "FSELECT" || type.label == "FLIST" || type.label == "LIST" || type.label == "PARAGRAPH" || type.label == "DROPDOWN"){
            curComp[this.state.componentIndex].push({
               type : type.label,
               label : placeholder,
               "meta-type" : type.meta,
               resmanMapping: type.resmanMapping ? type.resmanMapping : '',
               mandatory: type.mandatory,
               id: uuid.v4()
            });
         }else{
            curComp[this.state.componentIndex].push({
               type : type.label,
               id: uuid.v4(),
               placeholder : placeholder,
               resmanMapping: type.resmanMapping ? type.resmanMapping : '',
               mandatory: type.mandatory,
            });
         }  
      }
      this.setState({
         sections : curComp, 
         json : this.createJSON(curComp, this.state.title, this.props.data ? this.props.data._id : this.state._id, this.state.displayKeys ? this.state.displayKeys : [])
      });
   }

   _renderModal(){
      return ( 
         <EditorModal 
         getModels={this.props.getModels}
         callback={this.componentModalCallback.bind(this)}
         editing={this.state.editingElement}
         data={this.state.editingElement ? this.state.sections[this.state.editingComponentIndex][this.state.editingElementIndex] : this.state.sections[this.state.componentIndex]}
         show={this.state.showModal} 
         addSection={this.state.canAddSection}
         hasSection={this.hasSection()}
         onClose={this.onModalClose.bind(this)}/>);
   }

   changeTitleState(v){
      this.setState({
         editingTitle : v
      });
   }

   _renderTitle(){   
      var jsx = this.state.editingTitle ? 
         (<FormControl
            style={{padding : '25px 0 25px 0', margin : '10px 0 10px 0', fontSize : '32px'}}
            autoFocus={true}
            type="text"
            value={this.state.title}
            placeholder="Enter title"
            onChange={(e) => {
               this.setState({
                  title : e.target.value, 
                  json : this.createJSON(
                     this.state.sections, 
                     e.target.value, 
                     this.props.data ? this.props.data._id : this.state._id, 
                     this.state.displayKeys ? this.state.displayKeys : []
                  )
               });
            }}
            onKeyPress={(e) => {
               if(e.key === 'Enter'){ 
                  this.setState({editingTitle : false}) 
               }
            }}/>) : 
            (
               <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-between', alignItems : 'center', width : '100%'}}>
                  <h1 style={{
                     minWidth : '20%', 
                     flex : '1',
                     textAlign : 'left'
                  }} 
                  onClick={this.changeTitleState.bind(this, true)}>
                  {this.state.title == '' ? "Enter title" : this.state.title}
                  </h1>
                  <Glyphicon glyph="edit" style={{fontSize : '24px'}}></Glyphicon>
               </div>
            );

      return (
         <div style={{display : 'flex', justifyContent : 'flex-start', marginBottom : '10px', borderBottom : '1px solid #ccc'}}>  
         {jsx}
         </div>    
      );
   }


   _update(){
      var model = this.state.json;
      model['display_keys'] = this.state.displayKeys;
      this.props.updateData(model);
   }

   _generateJSON(){
      return (
         <JsonBuilder object={this.state.json} />
      ); 
   }

   render(){
      var showModel = false;
      if(this.props.showModel){
         showModel = this.props.showModel;
      }

      return(
         <div style={{display : 'flex', flexDirection : 'row', marginBottom : '10px', flex : 1}}>
            <div style={{
            paddingRight : '10px', 
            flexDirection : 'column',  
            height : '100%', 
            width : '100%', 
            position : 'relative'}}>
               {this._renderTitle()}
               {this._renderSections()}
               <Button style={{width : '100px', position : 'absolute', right : '0'}} bsStyle="info" onClick={this.onAddSection.bind(this)}>Add section</Button>
               <Button style={{
                  position : 'absolute',
                  left : 0
               }}
               bsStyle = "primary" 
               onClick = {() => {
                  this.reset();
                  this.state._id ? this._update() : this.props.newData(this.state.json);
               }}>Save Form</Button>
               {this._renderModal()}
            </div>
            {showModel ? this._generateJSON() : (<div/>)}
         </div>
      );
   }
}


