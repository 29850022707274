import React, { Component } from 'react';

import { Button, Glyphicon } from 'react-bootstrap';
import FormCreator from '../form-creator/index.js'
import JsonBuilder from './jsonBuilder.js'
var conf = require('../../conf');

export default class ModelViewer extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         view : 'main',
         modelIndex : -1,
         buttonIndex : -1,
         data : undefined
      }
   }

   _renderModels(){
      console.log("here:");
      return this.state.models.map((x, i) => {
         return (
            <div 
            onMouseEnter={() => {this.setState({modelIndex : i})}}
            onMouseLeave={() => {this.setState({modelIndex : -1})}}
            style={{borderRadius : '5px', position : 'relative', display : 'flex', height : '50px', flexDirection : 'row', border : '1px solid #ccc', margin : '0 0 5px 0'}}>
            <div 
            onMouseEnter={() => {this.setState({buttonIndex : i})}}
            onMouseLeave={() => {this.setState({buttonIndex : -1})}}
            style={
               i != this.state.buttonIndex ?    
               {
                  paddingLeft : '5px', 
                  display : 'flex', 
                  flex : 0.75, 
                  height : '100%', 
                  fontSize : '20px', 
                  alignItems : 'center', 
                  justifySelf : 'center'
               }:{
                  paddingLeft : '5px', 
                  display : 'flex', 
                  background : '#ddd',
                  flex : 0.75, 
                  height : '100%', 
                  fontSize : '20px', 
                  alignItems : 'center', 
                  justifySelf : 'center' 
               }}
            onClick={() => {
               this.setState({
                  view : 'builder',
                  data : x
               });
            }}>{x.name}</div>

            <div style={{
               display : 'flex', 
                  flex : 0.1, 
                  height : '100%', 
                  background : '#eee', 
                  justifyContent : 'center', 
                  alignItems : 'center', 
                  borderRight : '1px solid #ccc', 
                  borderLeft : '1px solid #ccc'}}>JSON</div>

            <div style={{
               display : 'flex', 
                  flex : 0.1, 
                  height : '100%', 
                  background : '#eee', 
                  justifyContent : 'center', 
                  alignItems : 'center'}}>Preview</div>

            <div style={{display : 'flex', flex : 0.05, height : '100%', background : '#eee', justifyContent : 'center', alignItems : 'center', borderLeft : '1px solid #ccc'}}
            onClick = {() => {
               this.removeForm();    
            }}>
            <Glyphicon glyph="remove" style={{fontSize : '20px', alignSelf : 'center'}}></Glyphicon>
            </div>
            </div>
         );
      }); 
   }

   addForm(form){
      this.postForm(form).then((res) => {
         this.setState({
            view: 'main',
            data : undefined
         });
      });
      this.props.update();
   }

   updateForm(form){
      var _id = form._id;
      return fetch(conf.baseURL + `/model/${_id}`, {
         method: 'POST',
         credentials: 'include',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            ...form
         })
      }).then((resp) => {
         return resp.json();
      }).then(res => {
         this.setState({
            view: 'main',
            data : undefined
         });
         this.props.getModels().then(r => {
            this.setState({
               models : r
            });
         });
         this.props.update();
      }); 
   }

   postForm(form){
      return fetch(conf.baseURL + '/model', {
         method: 'POST',
         credentials: 'include',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            ...form
         })
      }).then((resp) => {
         this.props.getModels().then(r => {
            this.setState({
               models : r
            });
         });
         return resp.json();
      });
   }

   removeForm(){
      var _id = this.state.models[this.state.modelIndex]._id; 
      fetch(conf.baseURL + `model/${_id}/remove` , {
         method: 'POST',
         credentials: 'include'
      }).then(res => {
         return res.json();
      }).then(res => {
         this.props.getModels().then(r => {
            this.setState({
               models : r,
               data : undefined
            });      
         });
         this.props.update();
      });
   }

   _renderAddModelButton(){
      return (
         <Button bsStyle="primary" 
         style={{
            alignSelf : 'center'
         }} onClick={() => {
            this.setState({view : 'builder'});
         }}>Add new model</Button>
      ); 
   }

   _render(){
      switch(this.state.view){
         case 'main': 
            return(
               <div style ={{    
                  display : 'flex',
                     flexDirection: 'column',
                     flex : 1,
                     width: '80%',
                     position: 'relative',
                     alignSelf: 'center',
                     margin : 'auto',
                     paddingRight: '10px'}}>
               <h3 style={{alignSelf : 'center'}}>Models</h3>
               {this._renderModels()}
               {this._renderAddModelButton()}
               </div>                      
            );
         case 'builder': 
            return(
               <FormCreator getModels={this.props.getModels} data = {this.state.data} newData = {this.addForm.bind(this)} updateData = {this.updateForm.bind(this)} />
            );
      } 
   } 

   render(){
      return(
         <div style={{
            overflowY : 'scroll',
               height : '100%',
               width: '95%',
               marginLeft: '5%'
         }}>
         {this._render()}
         </div>
      );
   }
}
