import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import {SketchField, Tools} from 'react-sketch';

export default class Signature extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         value : undefined,
         initialValue : '',
      };
   }

   componentWillReceiveProps(newProps) {
      if(this.props !== newProps){
         this.props = newProps;

         if(newProps.signature){
            this._sketch.setBackgroundFromDataUrl(newProps.signature,{
               stretched: true
            });    
         }
      }
      this.setState({
         ...newProps
      });
   }


   clearSketch() {
      this._sketch.clear();
      if(!this.props.onChange) return;
      this.props.onChange(this._sketch.toDataURL({

      }));
   }

   onSketchChange() {
      if(this.props.onChange)
         this.props.onChange(this._sketch.toDataURL({
         }
         ));
   }

   render(){
      return (
         <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 'min-content'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>{this.props.placeholder}<Button bsStyle="danger" onClick={() => this.clearSketch()} style={{margin: 0}}>Clear Signature</Button></div>
            <SketchField  
               style={{border: '1px solid #ccc'}}
               ref={(c) => this._sketch = c}
               height='100px' 
               imageFormat="jpeg"
               tool={Tools.Pencil} 
               lineColor='black'
               onChange={this.onSketchChange.bind(this)}
               lineWidth={2}/>
         </div>
      );
   }
}
