import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Glyphicon } from 'react-bootstrap';

export default class FileChooser extends Component{
   constructor(props){
      super(props);
      this.state = ({
         ...props,
         urls : []
      });
   }

    componentWillReceiveProps(newProps){
        if(this.props !== newProps){
            this.setState({...newProps});
        }
    }

   onFileAdded(url){
      let reader = new FileReader();
      reader.onload = (e) => {
            let result = {
                file: reader.result,
                fileName: url.name
            }    
            this.props.onChange(result);
            
       } 
      reader.readAsDataURL(url)
   }

   _saveFile(file){
      if(file){
         console.log(file);
         //Add file preview url
//         this.onFileAdded(file);
          this.state.connector.attachToModel(this.state.struct.id, file);

/*        this.state.connector.attachToModel(null, file).then((resp) => {
            console.log(resp);
         });
        */
         //some rrome-pouch method for file saving
      }
      else{
         console.log('no file selected');
      }
   }

   _renderDropZone(){
      if(this.state.file){
         return (<div style = {{display: 'flex'}}>
            <div style={{flex: 1}}>{this.state.file.name}
             - {this.state.file.size/1000} KB
             </div>
            <Glyphicon glyph = "remove" onClick = {() => {
               this.setState({
                  file: null
               });
               //some rrome-pouch method for file removal
            }}/>
         </div>);
      }
      else{
         return (
            <Dropzone maxSize={6000000} multiple={false} onDrop={(accepted, rejected) => {
                console.log("Files", accepted);
                if(accepted.length > 0){
                    this.setState({file:accepted[0]});
                    this._saveFile(accepted[0]);
                }
                if(rejected.length > 0) {
                   window.alert("6MB file size exceeded. Upload rejected.");
                }
            }} style={{
               width: '100%',
               minHeight: '100px',
               border: '1px solid rgb(0, 0, 0)',
               margin: '10px 2px',
               background: 'rgb(238, 238, 238)',
               cursor: 'pointer',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column',
               padding: '20px 0px'
            }}>
               Click or drag file here to upload (6MB max filesize)     
            </Dropzone>
         );
      }
   }

   render(){
      return(
            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
               <h4 style = {{alignSelf: 'center'}}>{this.state.struct.label}</h4>
               {this._renderDropZone()}
            </div>
      );   
   }
}
