import React, { Component } from 'react';

export default class Flat extends Component {
   constructor(props) {
      super(props);
      this.state = {...props};
   }

   componentDidMount() {
      this.props.history.push('/login');
   }

   render() {
      return (<div/>);
   }
}
