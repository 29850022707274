import React, { Component } from 'react';
// import Select from 'react-select';
import {FormGroup, ControlLabel, Select, FormControl} from 'react-bootstrap';


export default class Dropdown extends Component {
   constructor(props){
      super(props);
        console.log("@", props.value);
      this.state = {
         ...props
      };
   }

   componentWillReceiveProps(newProps) {
      if(this.props !== newProps){
         this.props = newProps;
        //console.log("&", newProps.value);
         this.setState({
            ...newProps
         });
      }
   }

   onChange(evt){
      console.log("in select onChange...");
      console.log(evt.target.value);
      this.setState({value: evt.target.value});
      if(this.props.onChange){
         this.props.onChange(evt.target.value);
      }  
   }

   isValid() {
      let result = (!this.props.mandatory)
         || (this.state.value && this.state.value.value)
         || (this.state.value && this.state.value.value === undefined)
      console.log("dropdown is valid:", result);
      return result;
   }

   render(){
      var options = this.state.options.map(x => {
         return {
            value: x,
            label: x
         };
      });
      return (
         <div>
            {/* <div style={{fontSize: '1.3em', marginTop: '10px'}}>{this.state.placeholder}</div> */}
            <FormGroup validationState={this.isValid() ? null : "error"}>
            <ControlLabel>{this.state.mandatory ? "* " : ""}{this.state.placeholder}</ControlLabel>
               <FormControl
                     componentClass="select"
                     options={options}
                     value={this.state.value && this.state.value.value != undefined  ? this.state.value.value : (this.state.value && typeof(this.state.value) == 'string') ? this.state.value : null}
                     placeholder={this.state.placeholder}
                     onChange={this.onChange.bind(this)}>
                  <option value={null} key={-1}/>
                  {options.map(item => (
                     <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
               </FormControl> 
            </FormGroup>
         </div>
      );
   }
}
