import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Modal, Button, FormControl, FormGroup, ControlLabel, Glyphicon } from 'react-bootstrap';

const upload = require('superagent')
var conf = require('../../../conf.js');

export default class Export extends Component {
	constructor(props){
		super(props);
		this.state = {
			...props,
         show : false,
         map : {},
         currentContract : {},
         currentExportable : undefined,
         exportables : [],
         filesToBeSent : undefined
		}
	}

   onDrop(acceptedFiles, rejectedFiles) {
      var file = acceptedFiles[0];
      this.setState({filesToBeSent : file}); 
   }

   componentWillMount() {
      this.refreshExportables();
   }

   refreshExportables() {
      fetch(conf.baseURL + '/exportables', {
         method : 'GET',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token')
         }
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         console.log('Exportables', r);
         this.setState({
            exportables : r
         });
      });
   }

   removeExportable(exportable_blob_id, exportable_id) {
      fetch(conf.baseURL + '/exportables/remove', {
         method : 'POST',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
            'Content-Type' : 'application/json'
         },
         body : JSON.stringify({
            exportable : {
               exportable_blob_id : exportable_blob_id,
               exportable_id : exportable_id
            }
         })
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         this.refreshExportables();
      });
   }

   /*Submit the file to the route as a octet stream*/
   onSubmit(){
      var file = this.state.filesToBeSent;
      upload.post(conf.baseURL + '/exportables/create')
         .withCredentials()
         .set('Authorization', 'Bearer ' + window.localStorage.getItem('token'))
         .attach('exportable', file)
         .field('name', this.state.name)
         .end((err, res) => {
            if (err) console.log(err);
            this.refreshExportables();
         });
      this.setState({
         show : false, 
         filesToBeSent : undefined
      }); 
   }

   setKeyValue(name, value) {
      var map = this.state.map;
      map[name] = value; 
      this.setState({
         map : map 
      });
   }

   _renderModal(){
      return(
			<Modal show={this.state.show}>
				<Modal.Header>
					<Modal.Title>Add Exportable</Modal.Title>
				</Modal.Header>
				<Modal.Body>
               <ControlLabel>Enter Exportable Title</ControlLabel>
               <FormControl
                  type="text"
                  value={this.state.name}
                  placeholder="Enter text"
                  onChange={e => this.setState({name : e.target.value})}
               />
               <ControlLabel style={{marginTop: '5px'}}>Upload Exportable</ControlLabel>
               <Dropzone onDrop={(files) => this.onDrop(files)} multiple={false} style={{
                  width : '100%',
                  height : '50px',
                  borderRadius : '10px',
                  border : '1px solid #ccc',
                  display : 'flex',
                  justifyContent : 'center',
                  alignItems : 'center'
               }}>
                  <div style={{userSelect : 'none'}}>
                     {!this.state.filesToBeSent ? 
                        'Click here to upload an exportable file' : 
                        this.state.filesToBeSent.name}
                  </div>
               </Dropzone>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={()=>{this.setState({show: false})}}>Close</Button>
					<Button onClick={this.onSubmit.bind(this)}bsStyle="primary">Add exportable</Button>
				</Modal.Footer>
			</Modal>
		); 
   }

   onExportableClick(x) {
      this.props.history.push('/admin/exportables/'+x.exportable_id);
   }

   _renderExportables(){
      if(this.state.exportables.length == 0){
         return(<h4 style={{textAlign: 'left', fontStyle: 'italic', width: '100%'}}>No current exportables</h4>);
      }
      return this.state.exportables.map(x => {
         return (
            <div style={{
               display : 'flex',
               flexDirection : 'row',
               width : '100%',
               height : '50px',
               marginTop : '5px',
            }}>
               <Button 
               onClick={this.onExportableClick.bind(this, x)}
               style={{
                  display : 'flex',
                  flex : 1,
                  marginTop : '10px',
                  textAlign : 'left',
                  paddingLeft : '20px',
                  height : '50px',
                  fontSize: '1.6em'
               }}>{x.exportable_name}</Button>
               <Button 
               onClick={() => {
                  if (window.confirm(`Delete ${x.exportable_name}?`)) {
                     this.removeExportable(x.exportable_blob_id, x.exportable_id);
                  }
               }}
               style={{
                  display : 'flex', 
                  justifyContent : 'center', 
                  alignItems : 'center',
                  marginLeft : '5px',
                  width : '50px',
                  marginTop : '10px',
                  textAlign : 'left',
                  height : '50px'
               }}><Glyphicon glyph="remove"/></Button>
            </div>
         );
      });
   }

	render(){
		return(
			<div style={{display: 'flex', width: '100%', justifyContent: 'center', overflowY: 'scroll'}}>
				<div style={{display: 'flex', width:'90%', flexDirection:'column', minHeight: 'min-content'}}>
					<h2 style={{textAlign: 'left', width: '100%'}}>Exportables</h2>
               {this._renderModal()}
               {this._renderExportables()}
               <Button bsStyle='primary' style={{
						fontSize: '1.2em',
						width: '190px',
						marginTop: '20px',
						alignSelf: 'flex-end'
					}}
					onClick={()=>{
						this.setState({show: true})
					}}>Add Exportable</Button>
				</div>
			</div>
		);
	}
}
