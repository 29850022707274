import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import {SketchField, Tools} from 'react-sketch';

export default class Signature extends Component {
   constructor(props){
      super(props);
      this.state = {
         value : undefined,
         ...props,
         initialValue : '',
      };
   }

   loadSketch(b64){
      this._sketch.setBackgroundFromDataUrl(b64, {
         stretched: true,
         stretchedX: false,
         stretchedY: false,
         originX: 'left',
         originY: 'top'
      });
   }

    componentDidMount(){
        if(this.state.value){
            this.loadSketch(this.state.value);
        }
    }

   componentWillReceiveProps(newProps) {
      if(this.props !== newProps){
         this.props = newProps;
         this.setState({
            ...newProps
         });
        if(newProps.value){
            this.loadSketch(newProps.value);
        }
      }
   }
  
   clearSketch() {
      this._sketch.clear();
      if(!this.props.onChange) return;
      this.props.onChange(this._sketch.toDataURL());
   }

   onSketchChange() {
      if(this.props.onChange)
         this.props.onChange(this._sketch.toDataURL());
   }

   render(){
      return (
         <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            width: '90%', 
            margin: '20px auto', 
            height: 'min-content'
         }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>Draw your Signature<Button bsStyle="danger" onClick={() => this.clearSketch()} style={{margin: 0}}>Clear Signature</Button></div>
            <SketchField  
            style={{border: '1px solid #ccc'}}
            ref={(c) => this._sketch = c}
            height='100px' 
            imageFormat="jpeg"
            tool={Tools.Pencil} 
            lineColor='black'
            onChange={this.onSketchChange.bind(this)}
            lineWidth={2}/>
         </div>
      );
   }
}
