import React, {
    Component
} from 'react';

import { Modal, Button, FormControl } from 'react-bootstrap';
var conf = require('../../../conf');

export default class Settings extends Component {
    constructor(props){
        super(props);
        this.state = {
            settings: {contactEmail: null, outputEmail: null},
            modalShow: false
        }
    }

    componentWillMount(){
        this.getSettings().then((r) => {
            this.setState({settings: r});
        });
    }

    getSettings(){
        return fetch(conf.baseURL + "/user/settings", {
            method: 'GET',
            credentials: 'include'
        }).then((r) => {    
            return r.json();
        });
    }

    updateSettings(){
        return fetch(conf.baseURL + "/user/settings", {
            method: "POST",
            credentials: "include",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                settings: this.state.settings
            })
        }).then((r) => {    
            return r.json();
        });
    }

    _changePassword(){
        if(this.state.password == this.state.confirm){
            this.changePassword().then((r) => {
                this.setState({modalShow: false, password: '', confirm: ''});
            });
        }else{
            //Error showing passwords don't match
        }
    }

    changePassword(){
        return fetch(conf.baseURL + "/user/password", {
            method: "POST",
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                password: this.state.password
            })
        }).then((r) => {
            return r.json();
        });        
    }

    onSettingChange(evt, name){
        var setting = this.state.settings;
        setting[name] = evt.target.value;
        this.setState({settings: setting});
    }

    render(){
        return (
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', overflowY: 'scroll'}}>
                <div style={{display: 'flex', width: '90%', flexDirection: 'column'}}>
                    <h2 style={{textAlign: 'left'}}>Settings</h2>
                    
                    <h4 style={{textAlign: 'left'}}>Contact Email</h4>
                    <FormControl placeholder="Contact Email" value={this.state.settings.contactEmail} onChange={(e) => this.onSettingChange(e, 'contactEmail')} />
                    
                    <h4 style={{textAlign: 'left'}}>Output Email</h4>
                    <FormControl placeholder="Output Email" value={this.state.settings.outputEmail} onChange={(e) => this.onSettingChange(e, 'outputEmail')}/>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                        <Button style={{marginRight: '10px'}} onClick={() => this.setState({modalShow: true})}>Change Password</Button> 
                       <Button bsStyle="primary" onClick={this.updateSettings.bind(this)}>Save Settings</Button>
                    </div>     
                </div>
            <Modal show={this.state.modalShow}>
                <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl placeholder="New Password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}/>
                    <FormControl style={{marginTop: '10px'}} value={this.state.confirm} onChange={(e) => this.setState({confirm: e.target.value})} placeholder="Confirm Password" />
                </Modal.Body>                

                <Modal.Footer>
                    <Button onClick={() => this.setState({password: '', confirm: '', modalShow: false})}>Cancel</Button>
                    <Button bsStyle="primary" onClick={this._changePassword.bind(this)}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            </div>
        );
    }
}
