import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class FSelect extends Component {
   /*Pass in placeholder as props*/
   constructor(props){
      super(props);
      this.state = {
         ...props,
         value : undefined
      };
   }

   render(){
      var options = [{value : '1', label : 'Option 1'}, 
                     {value : '2', label : 'Option 2'},
                     {value : '3', label : 'Option 3'}];
      return (
         <Select 
            name=""
            options={options}
            searchable={false}
            clearable={false}
            value={this.state.value ? this.state.value : ''}
            placeholder={this.state.placeholder}
            onChange={(value) => {
               this.setState({
                  value : value
               });
            }}
         />
      );
   }
}
