import React, { Component } from "react";
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import Select from "react-select";
var Spinner = require("react-spinkit");
var downloadjs = require("js-file-download");
var conf = require("../../../../conf.js");

export default class Focus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      show: false,
      name: "",
      email: "",
      contract: {},
      contracts: [],
      downloading: false,
    };
  }

  componentWillMount() {
    this.refresh();
  }

  makeChanges() {
    fetch(conf.baseURL + "/applicants/edit", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        applicant: {
          applicant_id: this.state.applicant.applicant_id,
          name: this.state.name,
          candidateid: this.state.candidateid,
          email: this.state.email,
          contract_id: this.state.contract.contract_id,
        },
      }),
    })
      .then(r => {
        return r;
      })
      .then(r => {
        return r;
      })
      .then(r => {
        console.log(r);
        this.refresh();
      });
  }

  onSubmit = e => {
    this.makeChanges();
    this.setState({
      show: false,
      name: "",
      email: "",
      contract: {},
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSelectChange = e => {
    if (e) this.setState({ contract: e.value });
  };

  getContracts() {
    fetch(conf.baseURL + "/contracts", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.setState({
          contracts: r,
        });
      });
  }

  refresh() {
    fetch(conf.baseURL + `/applicants/${this.props.match.params.id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        fetch(conf.baseURL + `/contracts/${r.contract_id}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
          credentials: "include",
        })
          .then(e => {
            return e.json();
          })
          .then(e => {
            return e;
          })
          .then(e => {
            this.setState({
              applicant: r,
              contract: e,
            });
            this.getContracts();
          });
      });
  }

  _renderDetails() {
    var Row = props => (
      <div>
        <h4
          style={{
            textAlign: "left",
            width: "100%",
            textDecoration: "underline",
          }}
        >
          {props.label}:
        </h4>
        <div
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "1.2em",
            marginBottom: "10px",
          }}
        >
          {props.value || <em style={{ "color": "#777" }}>blank</em>}
        </div>
      </div>
    );

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Row label="Name" value={this.state.applicant.name} />
        <Row
          label="Resman CandidateID"
          value={this.state.applicant.candidateid}
        />
        <Row label="Email" value={this.state.applicant.email} />
        <Row
          label="Contract Assigned"
          value={this.state.contract.contract_name}
        />
        <Row label="Application Status" value={this.state.applicant.status} />
      </div>
    );
  }

  _renderModal() {
    var value = this.state.contract
      ? { label: this.state.contract.contract_name, value: this.state.contract }
      : { label: "", value: {} };
    return (
      <Modal
        show={this.state.show}
        onKeyPress={e => {
          if (this.state.show && e.charCode == 13) {
            e.preventDefault();
            this.onSubmit();
          }
        }}
      >
        <Modal.Header>
          <Modal.Title>Edit Applicant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>Name</ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>
                Resman CandidateID
              </ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="candidateid"
                value={this.state.candidateid}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>Email</ControlLabel>
              <FormControl
                style={{ marginTop: "4px" }}
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup controlId="formBasicText">
              <ControlLabel style={{ marginTop: "5px" }}>
                Contract Assigned
              </ControlLabel>
              <Select
                searchable={false}
                clearable={false}
                style={{ marginTop: "4px" }}
                name="contract"
                value={value}
                onChange={this.onSelectChange}
                options={this.state.contracts.map(x => {
                  return { value: x, label: x.contract_name };
                })}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              this.setState({
                show: false,
                name: this.state.applicant.name,
                candidateid: this.state.applicant.candidateid,
                email: this.state.applicant.email,
                contract: this.state.contract,
              });
            }}
          >
            Close
          </Button>
          <Button bsStyle="primary" onClick={this.onSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  _download() {
    this.setState({ downloading: true });
    fetch(
      conf.baseURL + `/submissions/${this.props.match.params.id}/download`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(r => {
        return r.blob();
      })
      .then(r => {
        this.setState({ downloading: false });
        downloadjs(r, this.state.applicant.name + " Registration Packget.zip");
        console.log(r);
      });
  }

  resendMail() {
    //POST to /api/applicants/:id/resend
    var confirmation = window.confirm(
      `Send another invitation to ${this.state.applicant.email}?`
    );
    if (confirmation) {
      fetch(conf.baseURL + `/applicants/${this.props.match.params.id}/resend`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
        credentials: "include",
      });
    }
  }

  unsubmitApplication() {
    //POST to /api/applicants/:id/unsubmit
    if (window.confirm('Set this application status to "Not Completed"?')) {
      this.setState(
        prevState => ({
          applicant: { ...prevState.applicant, status: "Not Completed" },
        }),
        () => {
          fetch(conf.baseURL + "/applicants/status", {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: "Bearer " + window.localStorage.getItem("token"),
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              applicant_id: this.state.applicant.applicant_id,
              status: this.state.applicant.status,
            }),
          })
            .then(r => {
              return r;
            })
            .then(r => {
              return r;
            })
            .then(r => {
              console.log(r);
              this.refresh();
            });
        }
      );
    }
  }

  render() {
    if (this.state.applicant && this.state.contract) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{ display: "flex", width: "90%", flexDirection: "column" }}
          >
            {this._renderModal()}
            <h2 style={{ textAlign: "left", width: "100%" }}>
              {this.state.applicant.name}
            </h2>
            {this._renderDetails()}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {this.state.applicant.status == "Completed" ? (
                <Button
                  onClick={() => {
                    this._download();
                  }}
                  style={{
                    fontSize: "1.2em",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                >
                  {this.state.downloading ? (
                    <Spinner name="circle" />
                  ) : (
                    "Download"
                  )}
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  this.setState({
                    show: true,
                    name: this.state.applicant.name,
                    email: this.state.applicant.email,
                    candidateid: this.state.applicant.candidateid,
                  });
                }}
                style={{
                  fontSize: "1.2em",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
              >
                Edit
              </Button>
              {this.state.applicant.status == "Not Completed" ? (
                <Button
                  style={{
                    fontSize: "1.2em",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    window.location.href =
                      "/apply/" + this.state.applicant.applicant_id;
                  }}
                >
                  Open Contract
                </Button>
              ) : (
                <div />
              )}

              {this.state.applicant.status == "Not Completed" ? (
                <Button
                  bsStyle="primary"
                  style={{
                    fontSize: "1.2em",
                    marginTop: "20px",
                  }}
                  onClick={this.resendMail.bind(this)}
                >
                  Resend Email
                </Button>
              ) : (
                <Button
                  bsStyle="primary"
                  style={{
                    fontSize: "1.2em",
                    marginTop: "20px",
                  }}
                  onClick={() => this.unsubmitApplication()}
                >
                  Unsubmit Application
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
