import React, { Component } from "react";
import {
  Modal,
  ControlLabel,
  FormGroup,
  FormControl,
  Button,
  Checkbox,
} from "react-bootstrap";
import Select from "react-select";

var util = require("../../utils");

export default class EditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      editing: false,
      componentTypes: [
        "FSELECT",
        "FLIST",
        "LIST",
        "TEXT",
        "TEXTAREA",
        "DATE",
        "NUMBER",
        "CHECKBOX",
        "FILECHOOSER",
        "PARAGRAPH",
        "SIGNATURE",
        "DROPDOWN",
        "SECTION",
      ],
      value: undefined,
      models: [],
      fselectValue: undefined,
      fselectDisplayKeys: [],
      fselectDispValue: undefined,
      flistValue: undefined,
      flistDisplayKeys: [],
      flistListDisplay: [],
      flistDispValue: undefined,
      flistListDispValue: undefined,
      listValue: undefined,
      listMeta: [],
      dropdownMeta: [],
      paragraph: undefined,
      placeholder: undefined,
      resmanMapping: undefined,
      mandatory: undefined,
    };
  }

  componentWillMount() {
    this.props.getModels().then(r => {
      this.setState({
        models: r,
      });
    });
  }

  receivedFSELECTProps(newProps) {
    var state = {
      value: newProps.data.type,
    };
    var fulldisp = [];
    var disp = [];
    var fv = { value: "", label: "" };
    this.state.models.map(x => {
      if (x._id == newProps.data["meta-type"].id) {
        fv.value = x._id;
        fv.label = x.name;
        fulldisp = util.flatten(x.model);
      }
    });
    fulldisp.forEach(x => {
      if (!x.title) disp.push(x);
    });
    var displayKeys = [];
    disp.map(x => {
      newProps.data["meta-type"].display_keys.map(y => {
        if (x.id == y) {
          displayKeys.push({
            value: x.id,
            label: x.label,
          });
        }
      });
    });

    state.placeholder = newProps.data.label;
    state.fselectValue = fv;
    state.fselectDisplayKeys = disp;
    state.fselectDispValue = displayKeys;
    return state;
  }

  receivedFLISTProps(newProps) {
    var state = {
      value: newProps.data.type,
    };
    var fulldisp = [];
    var disp = [];
    var flv = { value: "", label: "" };
    this.state.models.map(x => {
      if (x._id == newProps.data["meta-type"].id) {
        flv.value = x._id;
        flv.label = x.name;
        fulldisp = util.flatten(x.model);
      }
    });
    fulldisp.forEach(x => {
      if (!x.title) disp.push(x);
    });
    var dk = [];
    var ldk = [];
    disp.map(x => {
      newProps.data["meta-type"].display_keys.map(y => {
        if (x.id == y) dk.push({ value: x.id, label: x.label });
      });
      newProps.data["meta-type"].list_display.map(y => {
        if (x.id == y) ldk.push({ value: x.id, label: x.label });
      });
    });
    state.flistDisplayKeys = disp;
    state.flistDispValue = dk;
    state.flistListDispValue = ldk;
    state.placeholder = newProps.data.label;
    state.flistValue = flv;
    return state;
  }

  receivedLISTProps(newProps) {
    var state = {};
    var vals = {
      TEXT: "Text",
      DATE: "Date",
      TEXTAREA: "Text Area",
      NUMBER: "Number",
      CHECKBOX: "Check Box",
    };
    state.placeholder = newProps.data.label;
    state.listMeta = newProps.data["meta-type"].map(x => {
      return {
        value: { value: x.type, label: vals[x.type] },
        type: x.type,
        label: x.label,
      };
    });
    return state;
  }

  receivedParagraphProps(newProps) {
    var state = {};
    state.placeholder = newProps.data.label;
    state.paragraph = newProps.data["meta-type"].paragraph;
    return state;
  }

  receivedDropdownProps(newProps) {
    var state = {};
    state.placeholder = newProps.data.label;
    state.dropdownMeta = newProps.data["meta-type"].options;
    return state;
  }

  componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      this.props = newProps;
      this.setState({
        ...newProps,
        componentTypes: [
          "FSELECT",
          "FLIST",
          "LIST",
          "TEXT",
          "TEXTAREA",
          "DATE",
          "CHECKBOX",
          "NUMBER",
          "FILECHOOSER",
          "PARAGRAPH",
          "SIGNATURE",
          "DROPDOWN",
          "SECTION",
        ],
      });

      //Check if we're editing an existing component
      if (newProps.editing) {
        var state = {};
        switch (newProps.data.type.toUpperCase()) {
          case "FSELECT":
            state = this.receivedFSELECTProps(newProps);
            break;
          case "FLIST":
            state = this.receivedFLISTProps(newProps);
            break;
          case "LIST":
            state = this.receivedLISTProps(newProps);
            break;
          case "PARAGRAPH":
            state = this.receivedParagraphProps(newProps);
            break;
          case "DROPDOWN":
            state = this.receivedDropdownProps(newProps);
            break;
          case "TEXT":
          case "TEXTAREA":
          case "DATE":
          case "NUMBER":
          case "CHECKBOX":
          case "FILECHOOSER":
          case "SIGNATURE":
            state.placeholder = newProps.data.placeholder;
        }
        state.resmanMapping = newProps.data.resmanMapping;
        state.mandatory = newProps.data.mandatory;
        state.value = {
          value: newProps.data.type.toLowerCase(),
          label: newProps.data.type,
        };
        this.setState({
          ...state,
          componentTypes: [
            "FSELECT",
            "FLIST",
            "LIST",
            "TEXT",
            "TEXTAREA",
            "DATE",
            "CHECKBOX",
            "NUMBER",
            "FILECHOOSER",
            "PARAGRAPH",
            "SIGNATURE",
            "DROPDOWN",
            "SECTION",
          ],
        });
      }
    }
  }

  saveSection() {
    var value = this.state.value;
    value.resmanMapping = this.state.resmanMapping;
    value.mandatory = this.state.mandatory;
    if (value.value.toUpperCase() == "FSELECT") {
      value.meta = {};
      if (this.state.fselectValue) {
        value.meta.id = this.state.fselectValue.value;
        value.meta.display_keys = this.state.fselectDispValue.map(x => {
          return x.value;
        });
      }
    } else if (value.value.toUpperCase() == "FLIST") {
      value.meta = {};
      value.meta.id = this.state.flistValue.value;
      value.meta.list_display = this.state.flistListDispValue.map(x => {
        return x.value;
      });
      value.meta.display_keys = this.state.flistDispValue.map(x => {
        return x.value;
      });
    } else if (value.value.toUpperCase() == "LIST") {
      value.meta = this.state.listMeta.map(x => {
        return {
          label: x.label,
          type: x.value.value,
        };
      });
    } else if (value.value.toUpperCase() == "PARAGRAPH") {
      value.meta = {
        paragraph: this.state.paragraph,
      };
    } else if (value.value.toUpperCase() == "DROPDOWN") {
      value.meta = {
        options: this.state.dropdownMeta,
      };
    }
    this.props.callback(
      this.state.data.id,
      this.state.value,
      this.state.placeholder,
      this.state.editing
    );
    this.props.onClose();
    this.setState({
      value: {},
      placeholder: "",
      paragraph: "",
      listMeta: [],
      flistValue: {},
      fselectValue: {},
      listValue: {},
      listMeta: [],
      dropdownMeta: [],
      resmanMapping: "",
      mandatory: false,
    });
  }

  _renderSectionSelector() {
    var placeholder = "Select component...";
    var comptypes = this.state.componentTypes.slice(); // Added "Slice" so following line doesn't mess up the state IDW
    if (!this.props.addSection) comptypes.splice(comptypes.indexOf("SECTION"));
    if (this.props.hasSection) comptypes = ["SECTION"];
    var options = comptypes.map(x => {
      return { value: x.toLowerCase(), label: x };
    });
    return (
      <Select
        name=""
        clearable={false}
        searchable={false}
        options={options}
        value={this.state.value}
        placeholder={placeholder}
        onChange={v => {
          this.setState({ value: v });
        }}
      />
    );
  }

  _renderFSELECTDisplayKeys() {
    var displayKeyOptions = [];
    this.state.fselectDisplayKeys.map(x => {
      displayKeyOptions.push({
        value: x.id,
        label: x.label,
      });
    });
    return (
      <Select
        name=""
        clearable={false}
        searchable={false}
        multi={true}
        options={displayKeyOptions}
        placeholder="Select display keys..."
        value={this.state.fselectDispValue}
        onChange={e => {
          console.log("Selected Display Keys", e);
          this.setState({
            fselectDispValue: e,
          });
        }}
      />
    );
  }

  _renderFSELECTInput() {
    var options = this.state.models.map(x => {
      return {
        value: x._id,
        label: x.name,
      };
    });

    /* Replace with scheme such as:
     * value : model.id
     * label : model.label
     *
     * Also each model contains the potential meta-types for display purposes*/
    return (
      <div>
        <FormControl
          style={{ margin: "5px 0 5px 0" }}
          type="text"
          placeholder="Enter label..."
          value={this.state.placeholder ? this.state.placeholder : ""}
          onChange={e => {
            this.setState({ placeholder: e.target.value });
          }}
        />
        <Select
          name=""
          style={{ margin: "0px 0 5px 0" }}
          clearable={false}
          searchable={false}
          options={options}
          placeholder="Select type..."
          value={this.state.fselectValue}
          onChange={e => {
            var disp = [];
            var fulldisp = [];
            this.state.models.map(x => {
              if (x._id == e.value) {
                fulldisp = util.flatten(x.model);
              }
            });
            fulldisp.forEach(x => {
              if (!x.title) disp.push(x);
            });
            console.log("Editor Modal Display Keys", disp);
            this.setState({
              fselectValue: e,
              fselectDisplayKeys: disp,
            });
          }}
        />
        {this._renderFSELECTDisplayKeys()}
      </div>
    );
  }

  _renderFLISTDisplayKeys() {
    var displayKeyOptions = [];
    this.state.flistDisplayKeys.map(x => {
      displayKeyOptions.push({
        value: x.id,
        label: x.label,
      });
    });
    return (
      <div>
        <Select
          style={{ margin: "0 0 5px" }}
          name=""
          clearable={false}
          searchable={false}
          multi={true}
          options={displayKeyOptions}
          placeholder="Select display keys..."
          value={this.state.flistDispValue}
          onChange={e => {
            this.setState({
              flistDispValue: e,
            });
          }}
        />
        <Select
          name=""
          clearable={false}
          searchable={false}
          multi={true}
          options={displayKeyOptions}
          placeholder="Select list display ..."
          value={this.state.flistListDispValue}
          onChange={e => {
            this.setState({
              flistListDispValue: e,
            });
          }}
        />
      </div>
    );
  }

  _renderFLISTInput() {
    var options = this.state.models.map(x => {
      return {
        value: x._id,
        label: x.name,
      };
    });
    /* Replace with scheme such as:
     * value : model.id
     * label : model.label
     *
     * Also each model contains the potential meta-types for display purposes*/
    return (
      <div>
        <FormControl
          style={{ margin: "5px 0 5px 0" }}
          type="text"
          placeholder="Enter label..."
          value={this.state.placeholder ? this.state.placeholder : ""}
          onChange={e => {
            this.setState({ placeholder: e.target.value });
          }}
        />
        <Select
          style={{ margin: "0 0 5px" }}
          name=""
          clearable={false}
          searchable={false}
          options={options}
          placeholder="Select models..."
          value={this.state.flistValue}
          onChange={e => {
            var disp = [];
            var fulldisp = [];
            this.state.models.map(x => {
              if (x._id == e.value) {
                fulldisp = util.flatten(x.model);
              }
            });
            fulldisp.forEach(x => {
              if (!x.title) disp.push(x);
            });
            this.setState({
              flistValue: e,
              flistDisplayKeys: disp,
            });
          }}
        />
        {this._renderFLISTDisplayKeys()}
      </div>
    );
  }

  _renderLISTInput() {
    var options = [
      {
        value: "TEXT",
        label: "Text",
      },
      {
        value: "DATE",
        label: "Date",
      },
      {
        value: "TEXTAREA",
        label: "Text Area",
      },
      {
        value: "NUMBER",
        label: "Number",
      },
      {
        value: "CHECKBOX",
        label: "Check Box",
      },
    ];

    var jsx = this.state.listMeta.map((x, i) => {
      return (
        <div style={{ borderBottom: "1px solid #ccc", marginBottom: "5px" }}>
          <Select
            name=""
            clearable={false}
            searchable={false}
            options={options}
            placeholder="Select input type..."
            value={x.value}
            onChange={v => {
              var meta = this.state.listMeta;
              meta[i].value = v;
              meta[i].type = v.label;
              this.setState({ listMeta: meta });
            }}
          />
          <FormControl
            style={{ margin: "5px 0 5px 0" }}
            type="text"
            placeholder="Enter item label..."
            value={x.label ? x.label : ""}
            onChange={e => {
              var meta = this.state.listMeta;
              meta[i].label = e.target.value;
              this.setState({ listMeta: meta });
            }}
          />
        </div>
      );
    });

    return (
      <div>
        <div style={{ borderBottom: "1px solid #ccc", marginBottom: "5px" }}>
          <FormControl
            style={{ margin: "5px 0 5px 0" }}
            type="text"
            placeholder="Enter list label..."
            value={this.state.placeholder ? this.state.placeholder : ""}
            onChange={e => {
              this.setState({ placeholder: e.target.value });
            }}
          />
        </div>
        {jsx}
        <Button
          style={{ margin: "5px" }}
          onClick={() => {
            var meta = this.state.listMeta;
            meta.push({});
            this.setState({ listMeta: meta });
          }}
        >
          Add
        </Button>
      </div>
    );
  }

  _renderDropdownInput() {
    var meta = this.state.dropdownMeta;
    var jsx = meta.map((x, i) => {
      return (
        <FormControl
          style={{ margin: "5px 0 5px 0" }}
          type="text"
          placeholder="Enter dropdown label..."
          value={this.state.dropdownMeta[i] ? this.state.dropdownMeta[i] : ""}
          onChange={e => {
            var meta = this.state.dropdownMeta;
            meta[i] = e.target.value;
            this.setState({ dropdownMeta: meta });
          }}
        />
      );
    });
    return (
      <div>
        <FormControl
          style={{ margin: "5px 0 20px 0" }}
          type="text"
          placeholder="Enter label..."
          value={this.state.placeholder ? this.state.placeholder : ""}
          onChange={e => {
            this.setState({ placeholder: e.target.value });
          }}
        />
        {jsx}
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => {
            var meta = this.state.dropdownMeta;
            meta.push("");
            this.setState({ dropdownMeta: meta });
          }}
        >
          Add Dropdown Field
        </Button>
      </div>
    );
  }

  _renderInput() {
    if (this.state.value)
      switch (this.state.value.value) {
        case "fselect":
          return this._renderFSELECTInput();
        case "flist":
          return this._renderFLISTInput();
        case "list":
          return this._renderLISTInput();
        case "paragraph":
          return (
            <div>
              <FormControl
                style={{ margin: "5px 0 5px 0" }}
                type="text"
                placeholder="Enter label..."
                value={this.state.placeholder ? this.state.placeholder : ""}
                onChange={e => {
                  this.setState({ placeholder: e.target.value });
                }}
              />
              <FormGroup>
                <ControlLabel>Enter paragraph (Markdown allowed)</ControlLabel>
                <FormControl
                  style={{ margin: "5px 0 5px 0" }}
                  type="text"
                  componentClass="textarea"
                  placeholder="Enter paragraph..."
                  value={this.state.paragraph ? this.state.paragraph : ""}
                  onChange={e => {
                    this.setState({ paragraph: e.target.value });
                  }}
                />
              </FormGroup>
            </div>
          );
        case "dropdown":
          return this._renderDropdownInput();
        case "number":
        case "date":
        case "text":
        case "filechooser":
        case "textarea":
        case "checkbox":
        case "signature":
          return (
            <FormControl
              style={{ margin: "5px 0 5px 0" }}
              type="text"
              placeholder="Enter placeholder..."
              value={this.state.placeholder ? this.state.placeholder : ""}
              onChange={e => {
                this.setState({ placeholder: e.target.value });
              }}
            />
          );
      }
  }

  closeModal() {
    this.props.onClose();
    this.setState({
      value: {},
      placeholder: "",
      listMeta: [],
      flistValue: {},
      fselectValue: {},
      listValue: {},
      listMeta: [],
      dropdownMeta: [],
      resmanMapping: "",
      mandatory: false,
    });
  }

  _renderResmanMapping() {
    return (
      <FormControl
        style={{ margin: "5px 0 5px 0" }}
        type="text"
        placeholder="Enter resman mapping..."
        value={this.state.resmanMapping ? this.state.resmanMapping : ""}
        onChange={e => {
          this.setState({ resmanMapping: e.target.value });
        }}
      />
    );
  }

  _renderMandatory() {
    return (
      <Checkbox
        checked={this.state.mandatory ? this.state.mandatory : false}
        onChange={e => this.setState({ mandatory: e.target.checked })}
      >
        Mandatory
      </Checkbox>
    );
  }

  _renderModal() {
    // this one
    return (
      <Modal show={this.state.show}>
        <Modal.Header>
          <Modal.Title>
            {this.state.editing ? "Edit component..." : "New component..."}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderResmanMapping()}
          {this._renderSectionSelector()}
          {this._renderInput()}
          {this.state.data &&
          ["TEXT", "DROPDOWN", "TEXTAREA", "NUMBER", "DATE"].includes(
            this.state.data.type
          )
            ? this._renderMandatory()
            : ""}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              this.closeModal();
            }}
          >
            Close
          </Button>
          <Button onClick={this.saveSection.bind(this)} bsStyle="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return <div>{this._renderModal()}</div>;
  }
}
