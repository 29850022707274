import React, {
	Component
} from 'react';

import { Button } from 'react-bootstrap';
import './style.css';
const crypto = require('crypto');
var conf = require('../../conf');

export default class ForgotPassword extends Component{
	constructor(props){
		super(props);
		this.state={
			...props,
			username: ''
		}
	}

    recoverPassword(){
        return fetch(conf.homeURL + "/p/recover", {
            method: "POST",
            headers: { "Content-Type": 'application/json'},
            body: JSON.stringify({
                username: this.state.username
            })
        }).then((r) => {
            return r.json();
        }); 
    }

    onSubmit(){
        this.recoverPassword().then((r) => {
            //Set error or success
            console.log(r);
        });
    }


	onChange = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;
		this.setState(state);
	};

	render() {
		return(
			<div style = {{display: 'flex', width: '100%', height: '100%', justifyContent: 'center'}}> 
				<form style = {{display: 'flex', width: '300px', flexDirection: 'column', alignItems: 'center', marginTop: '15%'}}> 
               <img src={require('../../images/logo.png')} className="login-logo"/>
					<label className="login">
						<input className="login-input login" type="text" name="username" placeholder="Username" value={this.state.username} onChange={this.onChange}/>
					</label>
					<Button style={{width: '100%'}}bsStyle = "primary" onClick={this.onSubmit.bind(this)}>Recover password</Button>

				</form>
			</div>
		);
	}
}

