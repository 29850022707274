import React, {
	Component
}from 'react';

import {Switch, Route} from 'react-router-dom'; 

import Main from './actual';
import Alt from './ty';

export default class Appli extends Component {
	constructor(props){
		super(props);
		this.state={
			...props
		}
	}

	render(){
		console.log(this.props.match.url);
		return(

		<Switch>	
			<Route exact path = {`${this.props.match.url}/completed`} component = {Alt} />
			<Route path = {`${this.props.match.url}/:id`} component = {Main} />
		</Switch>
		);
	}
}
