// this should be the entry point to your library
'use strict'
module.exports = {
   Table: require('./components/table').default,
   Form: require('./components/form').default,
   Editor: require('./components/editor').default,
   FormCreator: require('./components/form-creator').default,
   ModelViewer: require('./components/admin-model-viewer').default
};

