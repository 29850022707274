import React, {
	Component
} from 'react';
import { Switch, Route } from 'react-router-dom';
import DisplayContracts from './display-contracts';
import DisplayFormDetails from './display-form-details'; 
import EditPage from './edit-page';
var conf = require('../../../conf.js');

export default class Contracts extends Component{
   constructor(props){
      super(props);
      this.state = {
         ...props
      }
   }

   switchUrl(...names){
      var f  = names.map(x => encodeURI(x)).join('/');
      this.props.history.push(`${this.props.match.url}/${f}`);
   }


	render(){
      return(
         <Switch>
            <Route exact path = {`${this.props.match.url}`}  
            render={() => (<DisplayContracts switchUrl={this.switchUrl.bind(this)}/>)}/>
            <Route exact path = {`${this.props.match.url}/:name`} 
            render={(props) => (<DisplayFormDetails switchUrl={this.switchUrl.bind(this)} {...props}/>)}/>
            <Route path = {`${this.props.match.url}/:name/:page`}
            render={(props) => (<EditPage {...props} />)}/>
         </Switch>
      );
	}
}

