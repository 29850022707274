import React, { Component } from 'react';
import { Modal, Button, FormControl, FormGroup, ControlLabel, Glyphicon } from 'react-bootstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import {SketchField,Tools} from 'react-sketch';
const upload = require('superagent')
var mime = require('mime-to-extensions');

var conf = require('../../../conf.js');

export default class ExportableFocus extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showModal: false,
         map: {},
         exportable: {exportable_name: 'Loading...'},
         currentContract: {},
         contracts: [],
         ...props
      };
   }

   componentWillMount() {
      this.refreshExportable(x => {
         this.refreshContracts(() => {
            this.loadPreFilledOptions(x);
         });
      });
   }

   componentWillReceiveProps(newProps) {
      if(this.props != newProps) {
         this.props = newProps;
         this.setState({...newProps});
      }
   }

   refreshContracts(cb) {
      fetch(conf.baseURL + '/contracts', {
         method : 'GET',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token')
         }
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         this.setState({
            contracts : r
         }, () => cb());
      });
   }

   loadPreFilledOptions(x) {
      var state = {};
      var contract = undefined;
      for(var j = 0; j < this.state.contracts.length; j++) {
         if(x.exportable_keys_contract_id === this.state.contracts[j].contract_id) {
            contract = this.state.contracts[j];
         }
      }
      function flatten(arr) {
         return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
         }, []);
      }
      if(contract){
         var cur = contract; 
         var pages = cur.pages;
         var models = pages.map(j => {
            return j.struct;
         }).map(j => {
            return j.model;
         });
         var model = flatten(models);
         var options = [];
         var state = {
            map : {}
         };
         //Create the options for react select 
         model.map(j => {
            if(j.id) {
               console.log("j: ", j);
               options.push({
                  value : j.id,
                  label : `${j.resmanMapping}\t "${j.label}"`
               });
            }
         });
         //Map the already mapped options to their corresponding state points
         Object.keys(x.exportable_keys).map((k, j) => {
            state.map['item' + j] = {};
            var id = x.exportable_keys_contract[k];
            options.map(m => {
               if(m.value === id){
                  state.map['item' + j] = m;
               }
            }); 
            if(id && (id.startsWith('pre_') || id.startsWith('sign_')))
               state.map['item' + j] = id;
         });
         state = {
            currentContract : contract ? {
               value : contract.contract_id,
               label : contract.contract_name
            } : {},
            map : state.map 
         };
      }
      this.setState({
         currentExportable : x,
         ...state 
      });
   }

   refreshExportable(cb) {
      fetch(conf.baseURL + '/exportables/' + this.props.match.params.id + '/single', {
         method : 'GET',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token')
         }
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         console.log('Exportable for focus', r);
         this.setState({
            exportable : r
         }, () => cb(r));
      });
   }

   setKeyValue(name, value) {
      var map = this.state.map;
      map[name] = value; 
      this.setState({
         map : map 
      });
   }
   
   onSubmitKeys(){
      var exp_keys_contract = {};
      Object.keys(this.state.currentExportable.exportable_keys).map((x, i) => {
         exp_keys_contract[x] = this.state.map['item' + i] ? this.state.map['item' + i] : 'NULL';
         if(typeof exp_keys_contract[x] === "object")
            exp_keys_contract[x] = exp_keys_contract[x].value;
      });
      fetch(conf.baseURL + `/exportables/${this.state.currentExportable.exportable_id}/update`, {
         method : 'POST',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
            'Content-Type' : 'application/json'
         },
         body : JSON.stringify({
            exportable_keys_contract : exp_keys_contract,
            exportable_keys_contract_id : this.state.currentContract.value
         })
      }).then(r => {
         return r.json();
      }).then(r => {
         this.props.history.push('/admin/exportables'); 
      })
   }

   _renderKeys() {
      function flatten(arr) {
         return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
         }, []);
      }
      var cur = this.state.currentContract;
      if(cur.value){
         for(var i = 0; i < this.state.contracts.length; i++){
            if(this.state.contracts[i].contract_id === cur.value){
               cur = this.state.contracts[i];
               break;
            }
         }
         var pages = cur.pages;
         
         var models = pages.map(x => { return x.struct; }).map(x => { return x.model; });
         var model = flatten(models);

         var options = [];
         model.map(x => {
            console.log("x", x);
            if(x.id) options.push({
               value : x.id,
               label : `${x.resmanMapping}\t "${x.label}"`
            }); 
         });
         return Object.keys(this.state.currentExportable.exportable_keys).map((x, i) => {
            //Check if the type we're looking at is pre filled
            let pre = false;
            let sign = false;
            if(typeof this.state.map['item'+i] === "string"){
               pre = this.state.map['item'+i].startsWith("pre_") || this.state.map['item'+i].startsWith('sign_');
               sign = this.state.map['item'+i].startsWith('sign_'); 
               console.log(x, pre, sign);
            }

            //Now check if the type is allowed for prefilling
            let type = "text";
            let id = x.replace(/ |\/|\\|\./g, "_");
            if(this.state.currentExportable.acroform_info) {
               this.state.currentExportable.acroform_info.map(a => {
                  if(a.id === id) {
                     type = a.type;
                  }
               });
            }

            //Now we need to check if we're allowed to pre fill this option for
            //the acroform object
            let canPreFill = type === "alpha";


            let renderSelectOrSignature = () => {
               if(pre && sign) {
                  return (
                     <SketchField  
                     key={i}
                     style={{border:'1px solid #ccc',width:'300px'}}
                     ref={(c) => {
                        this._sketch = c;
                        if(c && this.state.map['item'+i] && this.state.map['item'+i].startsWith("sign_"))
                           c.setBackgroundFromDataUrl(this.state.map['item'+i].split("sign_")[1], {stretchedX:'300px', stretchedY:'100px'});
                     }}
                     height='100px' 
                     imageFormat="jpeg"
                     tool={Tools.Pencil} 
                     lineColor='black'
                     onChange={() => {
                        let sig = this._sketch.toDataURL();
                        this.setKeyValue('item'+i, "sign_" + sig);
                     }}
                     lineWidth={2}/>
                  );
               } else if(pre && !sign) {
                  return (
                     <input key={i} style={{width:'300px'}} value={this.state.map['item'+i].split('pre_')[1]} onChange={e => {
                        this.setKeyValue('item' + i, "pre_" + e.target.value);
                     }}/>
                  );
               }else {
                  return (
                     <Select
                     key={i}
                     style={{width : '300px'}}
                     options={options}
                     clearable={true}
                     value={this.state.map['item' + i] ? this.state.map['item' + i] : {}}
                     onChange={e => this.setKeyValue('item' + i, e)}
                     />
                  );
               }

            }

            return (
               <div key={i} style={{width : '100%', display : 'flex', flexDirection : 'row', marginTop : '10px'}}>
                  <div style={{display : 'flex', width : '50%', flex: canPreFill ? 'initial' : 1, alignItems : 'center'}}>{x}</div> 
                  {canPreFill ? "Prefill" : ""}
                  {canPreFill ? (
                     <input type="checkbox" value="prefill" checked={pre} style={{flex:1}}
                     onChange={() => this.setKeyValue('item' + i, pre ? '' : "pre_")}/>
                  ) : null}
                  {pre ? 'Signature' : ""}
                  {pre ? (
                     <input type="checkbox" value="sign" checked={sign} style={{flex:1}}
                     onChange={() => this.setKeyValue('item' + i, "sign_")}/>
                  ) : null}
                  {renderSelectOrSignature()} 
               </div>
            );
         })
      } else {
         return null; 
      }
   }

   DownloadTemplate() {

      var ext = 'pdf';
      var type = 'application/pdf';

      console.log("Download Template clicked");
      return fetch(`${conf.baseURL}/exportables/${this.state.currentExportable.exportable_blob_id}`, {
         method: "GET",
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
         },
         credentials: 'include'
      }).then((r) => {
         console.log("Downloaded");
         // type = r.headers.get('Content-Type');
         // ext = mime.extension(type);
         return r.blob();
      }).then((blob) => {
         require("js-file-download")(blob,`${this.state.currentExportable.exportable_name}.${ext}`);
         //this.setState({loading: false});
     });
   }

   ReplaceTemplate() {
      console.log("Replace Template clicked");
      this.setState({showModal:true});
   }

    updateTitle(title){
        this._updateTitle(title).then((r) => {
            console.log("Updated title ", title, r);
        });
    }

    _updateTitle(title){
        return fetch(`${conf.baseURL}/exportables/${this.state.currentExportable.exportable_id}/update`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            credentials: 'include',
            body: JSON.stringify({
                title: title
            })
        }).then((r) => {
            return r.json();
        });
    }

    _renderTitle(){
        var jsx = this.state.editingTitle ? 
            (<FormControl
                style={{padding: '25px 0 25px 0', margin: '10px 0 10px 0', fontSize: '32px'}}
                autoFocus={true}
                type="text"
                value={this.state.exportable.exportable_name}
                placeholder="Enter title"
                onChange={(e) => {
                    var exportable = this.state.exportable;
                    exportable.exportable_name = e.target.value;
                    this.setState({
                        exportable: exportable
                    });
                }}

                onKeyPress={(e) => {
                    if(e.key === 'Enter'){
                        this.setState({editingTitle: false});
                        this.updateTitle(this.state.exportable.exportable_name);
                    }
                }}
            />) : 

            (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <h2 style={{
                        minWidth: '20%',
                        flex: 1,
                        textAlign: 'left'
                    }}
                    onClick={() => this.setState({editingTitle: true})}>
                    {this.state.exportable.exportable_name}
                    </h2>
                </div>

            );
            
        return (
            <div>
                {jsx}
            </div>
        );
    }

    onDrop(acceptedFiles, rejectedFiles) {
      var file = acceptedFiles[0];
      this.setState({filesToBeSent : file}); 
   }

      /*Submit the file to the route as a octet stream*/
      onSubmit(){
         var file = this.state.filesToBeSent;
         upload.post(conf.baseURL + `/exportables/${this.state.currentExportable.exportable_id}/updateTemplate`)
            .withCredentials()
            .set('Authorization', 'Bearer ' + window.localStorage.getItem('token'))
            .attach('exportable', file)
            .end((err, res) => {
               if (err) console.log(err);
            });
         this.setState({
            showModal : false, 
            filesToBeSent : undefined
         }); 
      }

    _renderModal() {
       return <Modal show={this.state.showModal}>
       <Modal.Header>
          <Modal.Title>Update Exportable</Modal.Title>
       </Modal.Header>
       <Modal.Body>
          {/* <ControlLabel>Enter Exportable Title</ControlLabel> */}
          {/* <FormControl
             type="text"
             value={this.state.name}
             placeholder="Enter text"
             onChange={e => this.setState({name : e.target.value})}
          /> */}
          {/* <ControlLabel style={{marginTop: '5px'}}>Upload Exportable</ControlLabel> */}
          <Dropzone onDrop={(files) => this.onDrop(files)} multiple={false} style={{
             width : '100%',
             height : '50px',
             borderRadius : '10px',
             border : '1px solid #ccc',
             display : 'flex',
             justifyContent : 'center',
             alignItems : 'center'
          }}>
             <div style={{userSelect : 'none'}}>
                {!this.state.filesToBeSent ? 
                   'Click here to upload an exportable template' : 
                   this.state.filesToBeSent.name}
             </div>
          </Dropzone>
       </Modal.Body>
       <Modal.Footer>
          <Button onClick={()=>{this.setState({showModal: false, filesToBeSent : undefined})}}>Close</Button>
          <Button onClick={this.onSubmit.bind(this)} bsStyle="primary">Replace exportable template</Button>
       </Modal.Footer>
    </Modal>
    }

   render() {
      var options = this.state.contracts.map(x => {
         return {
            value : x.contract_id,
            label : x.contract_name
         };
      });
      return (
         <div style={{display: 'flex', width: '100%', justifyContent: 'center', overflowY: 'scroll'}}>
				<div style={{display: 'flex', width:'90%', flexDirection:'column', minHeight: 'min-content'}}>
			    {this._renderTitle()}
             {this._renderModal()}	
               <Button onClick={() => this.DownloadTemplate()} >Download Template <Glyphicon glyph="download" style={{fontSize: '24px', padding:'6px'}}></Glyphicon></Button>
               <Button onClick={() => this.ReplaceTemplate()}>Replace Template <Glyphicon glyph="upload" style={{fontSize: '24px', padding:'6px'}}></Glyphicon></Button>
               <ControlLabel>Select Associated Contract</ControlLabel> 
               <Select
               id="state-select"
               autoFocus
               options={options}
               clearable={false}
               value={this.state.currentContract}
               onChange={e => this.setState({currentContract : e})}
               /> 
               {this._renderKeys()}
               <Button bsStyle='primary' style={{
						fontSize: '1.2em',
						width: '190px',
						marginTop: '20px',
						alignSelf: 'flex-end'
					}}
					onClick={this.onSubmitKeys.bind(this)}>Save</Button>
				</div>
			</div>

      );
   }
}
