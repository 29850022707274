import React, {
	Component
} from 'react';

import { Button } from 'react-bootstrap';
import './style.css';
const crypto = require('crypto');
var conf = require('../../conf');
var qs = require('qs');

export default class ResetPassword extends Component{
	constructor(props){
		super(props);
		this.state={
			...props,
            token: qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).token
		}
	}

    resetPassword(password){
        return fetch(conf.homeURL + "/p/reset-password", {
            method: "POST",
            headers: { "Content-Type": 'application/json'},
            body: JSON.stringify({
                token: this.state.token,
                password: password
            })
        }).then((r) => {
            return r.json();
        }); 
    }

    onSubmit(){
        if(this.state.password == this.state['confirm-password']){
            this.setState({error: ''});
            this.resetPassword(this.state.password).then((r) => {
                console.log(r);
                if(!r.error){
                    this.props.history.push('/login');
                }
            });
        }else{
            this.setState({error: 'Passwords must match'});
        }
    }


	onChange = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;
		this.setState(state);
	};

	render() {
		return(
			<div style = {{display: 'flex', width: '100%', height: '100%', justifyContent: 'center'}}> 
				<form style = {{display: 'flex', width: '300px', flexDirection: 'column', alignItems: 'center', marginTop: '15%'}}> 
               <img src={require('../../images/logo.png')} className="login-logo"/>
                    <div>{this.state.error}</div>
					<label className="login">
						<input className="login-input login" type="password" name="password" placeholder="New Password" value={this.state.password} onChange={this.onChange}/>
					</label>
                    <label className="login">
                        <input className="login-input login" type="password" name="confirm-password" placeholder="Confirm Password" value={this.state['confirm-password']} onChange={this.onChange}/>
                    </label>
					<Button style={{width: '100%'}}bsStyle = "primary" onClick={this.onSubmit.bind(this)}>Change password</Button>

				</form>
			</div>
		);
	}
}

