import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';

export default class FileChooser extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props
      };
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.setState({
            ...newProps
         });
      }
   }

   _renderDropZone(){
      return (
         <Dropzone onDrop={(acc, rej) => {}} style={{
            width: '100%',
            minHeight: '100px',
            border: '1px solid rgb(0, 0, 0)',
            margin: '10px 2px',
            background: 'rgb(238, 238, 238)',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px 0px'
         }}> Click or drag files here to upload </Dropzone> 
      );
   }

   render(){
      return (
         <div style={{display: 'flex', flex: 1, flexDirection : 'column'}}>
            <h4 style = {{alignSelf: 'center'}}> {this.state.title} </h4>
            {this._renderDropZone()}
         </div>
      );
   }
}
