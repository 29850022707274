import React, {
   Component
} from 'react'

import {
   FormControl,
   FormGroup
}  from 'react-bootstrap';

import Input from './items';
import List from './list';
import ForeignSelector from './foreignSelector';
import ForeignList from './foreignList';
import FileChooser from './fileChooser';
import Paragraph from './paragraph';
import Checkbox from './checkbox';
import Signature from './signature';
import Dropdown from './dropdown';

var utils = require("../utils");


export default class Section extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         content: {},
         containsSect: false
      }
   }

   isArray(){
      var r = false;
      this.state.struct.map(x => {
         if(Array.isArray(x))
            r = true; 
      });
      return r;
   }

   componentWillMount(){
      this.setState({
         containsSect : this.isArray() 
      });
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.setState({
            ...newProps
         }, () => {
            this.setState({ containsSect : this.isArray() });
         });
      }

      if(this.props.struct !== newProps.struct){
         this.setState({
            content: {}
         });
      }
   }

   mapChange(id, val, mapping, mandatory){
      var change = {};
      change[id] = {value: val, resmanMapping: mapping, mandatory: mandatory};
      console.log(id, val, mapping);
      var content = {
         ...this.state.content,
         ...change
      }
      this.setState({
         content:content
      });

      if(this.props.onChange){
         this.props.onChange(content);
      }
   }

   _renderItems(){
      return (<div>{this.state.struct.map((x, i) => {
         if(this.state.inMulti || i > 0)
            switch(x.type){
               case "LIST":
                  return(<List key={i} value = {(x.value) ? x.value : []} struct = {x} onChange={(evt) => {
                     this.mapChange(x.id, evt)
                  }}/>);
               case "FSELECT":
                  return(<ForeignSelector key={i} connector={this.state.connector} struct = {x} onChange={(evt) => this.mapChange(x.id, evt.value)} value={x.value} />);
               case "FLIST":
                  return (<ForeignList key={i} connector={this.state.connector} struct = {x} onChange={(evt) => this.mapChange(x.id, evt)} value={x.value} />);
               case "FILECHOOSER":
                  return (<FileChooser key={i} connector={this.state.connector} struct={x} onChange={(evt) => this.mapChange(x.id, evt)} value={x.value}/>);
               case "PARAGRAPH":
                  return (<Paragraph key={i} paragraph={x["meta-type"].paragraph} />);
               case "CHECKBOX":
						return (<Checkbox key={i} checked={x.value} label={x.label} onChange={(evt) => {this.mapChange(x.id, evt, x.resmanMapping)}} />);
               case "SIGNATURE":
                  return (<Signature key={i} signature={x.value} placeholder={x.label} onChange={e => this.mapChange(x.id, e)} />);
               case "DROPDOWN":
                  return (<Dropdown key={i} mandatory={x.mandatory} placeholder={x.label} value={x.value} options={x["meta-type"].options} onChange={e => this.mapChange(x.id, e, x.resmanMapping, x.mandatory)}/>)
					default:
                  if(!Array.isArray(x)){
                     if(this.state.inMulti)
                        return(<Input key={i} type = {x.type} placeholder={x.label} onChange={(evt) => { this.mapChange(x.id, evt) }} value={this.state.content[x.id]} />);
                     else {
                        return(<Input key={i} mandatory={x.mandatory} type = {x.type} placeholder={x.label} onChange={(evt) => { this.mapChange(x.id, evt, x.resmanMapping, x.mandatory) }} value={x.value} />);
                     }
                  }
                  else{
                     return(<Section key={i} connector = {this.state.connector} inMulti = {true} struct = {x} onChange = {this.props.onChange.bind(this)}/>);
                  }
            }

         }
         )}</div>);
   }

   _renderSectionTitle(){
      return (<div style={{fontAlign : 'left', fontSize : '25px'}}>{this.state.struct[0].title}</div>);
   }

   render(){
      var style = {
         display : 'flex',
         width : '100%',
         flexDirection: (this.state.containsSect) ? 'row' : 'column', 
         justifyContent: 'flex-start', 
         flexWrap: (this.state.inMulti) ? null : null, 
         minHeight: 'min-content',
         flex : 1
      };
      if(!this.state.inMulti){
         style.margin = '0 0 10px';
         style.paddingBottom = '10px';
         style.borderBottom = '1px solid #ccc';
      }else{
         style.padding = '5px';
      }

      return(
         <div style={{display : 'flex', flex : this.state.inMulti ? 1 : 'initial', minHeight : 'min-content', flexDirection : 'column', width : this.state.inMulti ? 'auto' : '80%', alignItems : this.state.inMulti ? 'initial' : 'left'}}>
            {!this.state.inMulti ? this._renderSectionTitle() : ''}
            <div style={style}>
               {this._renderItems()}
            </div>
         </div>
      );
   }
}

