import React, { Component } from 'react';
//import { FormCreator } from 'rrome-react';
var conf = require('../../../../conf.js');
var { FormCreator } = require('../../../rrome-react/index.js');

export default class EditPage extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         contracts : []
      }
   }
   
   componentWillReceiveProps(newProps) {
      if(this.props !== newProps){
         this.props = newProps;
         this.setState({
            ...newProps
         });
      }
   }

   componentWillMount(){
      this.init();
   }

   init() {
      fetch(conf.baseURL +'/contracts', {
         method : 'GET',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
         },
         credentials : 'include'
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         var title = decodeURI(this.props.match.params.name);
         var page = decodeURI(this.props.match.params.page);
         var page_id = '';
         var id = '';
         var struct = {};

         for(var i = 0; i < r.length; i++){   
            if(r[i].contract_name == title){
               id = r[i].contract_id;
               for(var j = 0; j < r[i].pages.length; j++){
                  if(r[i].pages[j].title == page){
                     page_id = r[i].pages[j].page_id;
                     struct = r[i].pages[j].struct;
                  }
               }
            }
         }

         if(id == '')
            this.props.history.push(this.props.match.url.substr(0, this.props.match.url.lastIndexOf('/')));
         this.setState({
            contracts : r,
            id : id,
            struct : struct,
            page_id : page_id
         });
      }); 
   }

   refreshContracts(){
      fetch(conf.baseURL +'/contracts', {
         method : 'GET',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
         },
         credentials : 'include'
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         var title = decodeURI(this.props.match.params.name);
         var page = decodeURI(this.props.match.params.page);
         var page_id = '';
         var id = '';

         for(var i = 0; i < r.length; i++){   
            if(r[i].contract_name == title){
               id = r[i].contract_id;
               for(var j = 0; j < r[i].pages.length; j++){
                  if(r[i].pages[j].title == page){
                     page_id = r[i].pages[j].page_id;
                  }
               }
            }
         }

         if(id == '')
            this.props.history.push(this.props.match.url.substr(0, this.props.match.url.lastIndexOf('/')));
         this.setState({
            contracts : r,
            id : id,
            page_id : page_id
         });
      });
   }


   async getModels() {
      return [];
   }

   onSavePage(json) {
      fetch(conf.baseURL +`/contracts/${this.state.id}/${this.state.page_id}/struct`, {
         method : 'POST',
         credentials : 'include',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'), 
            'Content-Type' : 'application/json'
         },
         body : JSON.stringify({struct : json})
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         this.refreshContracts();
      });

      var url = this.props.match.url;
      url = url.substr(0, url.lastIndexOf('/'));
      this.props.history.push(url);
   }

   render(){
      var pageTitle = this.props.match.params.page;
      console.log('STRUCT', this.state.struct);
      return (
         <div style={{display : 'flex', width : '100%', justifyContent : 'center'}}>
            <div style={{
               display : 'flex',
               marginTop : '15px',
               width : '90%'
            }}>
               <FormCreator data={this.state.struct} getModels={this.getModels} updateData={this.onSavePage.bind(this)} newData={this.onSavePage.bind(this)} showModel={false}/>
            </div>
         </div>
      );
   }
}
