import React,{
	Component
}from 'react';

import { Switch, Route } from 'react-router-dom';

import Main from './main';
import Focus from './focus';

export default class ApplicantSwitcher extends Component {
	constructor(props){
		super(props);
		this.state={
			...props,
			id: ''
		}
	}
	
	switchUrl(id){
		this.props.history.push(`${this.props.match.url}/${id}`);
		this.setState({
			id : id
		});
	}

	render(){
		return(
			<Switch>
				<Route exact path = {`${this.props.match.url}`}
					render = {(props) => (<Main onFocus = {this.switchUrl.bind(this)}/>)} />
				<Route exact path = {`${this.props.match.url}/:id`}
					render = {(props) => (<Focus {...props} />)} />
			</Switch>
		);
	}	
}
