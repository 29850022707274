import React, {
	Component
} from 'react';
import { 
   Modal, 
   Button, 
   FormControl, 
   FormGroup, 
   ControlLabel,
   Glyphicon
} from 'react-bootstrap';
var conf = require('../../../../conf.js');

export default class DisplayFormDetails extends Component{
	constructor(props){
		super(props);
		this.state = {
			...props,
         show : false,
         value : '',
         contracts : [],
			pages: [],
			title: "title"
		}
	}

   
   componentWillMount(){
      this.refreshContracts();
   }

   refreshContracts(){
      fetch(conf.baseURL +'/contracts', {
         method : 'GET',
         headers : {
            'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
         },
         credentials : 'include'
      }).then(r => {
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         var title = decodeURI(this.props.match.params.name);
         var id = '';

         for(var i = 0; i < r.length; i++){   
            if(r[i].contract_name == title){
               id = r[i].contract_id;
            }
         }

         if(id == '')
            this.props.history.push(this.props.match.url.substr(0, this.props.match.url.lastIndexOf('/')));
         this.setState({
            contracts : r,
            id : id
         });
      });
   }

   onClick() {
      this.setState({
         show : true
      }); 
   }

    _createPage(){
        return fetch(conf.baseURL + `/contracts/${this.state.id}/insert`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                page_struct: {
                    title: this.state.value,
                    struct: {}
                }   
            }),
            credentials: 'include'
        }).then((r) => {
            return r.json();
        });
    }

    _savePage(){
        return fetch(conf.baseURL + `/contracts/${this.state.id}/${this.state.selectedPage.page_id}/edit`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: this.state.value
            }),
            credentials: 'include'
        }).then((r) => {
            return r.json();
        });
    }

   savePage() {
        if(!this.state.selectedPage){
            this._createPage().then((r) => {
                this.refreshContracts();
            });
        }else{
            this._savePage().then((r) => {
                this.refreshContracts();
            });
        }  

      this.setState({
         show : false,
         selectedPage: null,
         value : ''
      }); 
   } 

   handleChange = (e) => {
      this.setState({ value: e.target.value });
   }

   _renderModal(){
      return(
         <Modal show={this.state.show}>
            <Modal.Header>
               <Modal.Title>{(this.state.selectedPage) ? "Edit page" : "Create new page" }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <form>
                  <FormGroup
                     controlId="formBasicText"
                  >
                     <ControlLabel>{(this.state.selectedPage) ? "Edit" : "Enter new"} page title</ControlLabel>
                     <FormControl
                        type="text"
                        value={this.state.value}
                        placeholder="Enter text"
                        onKeyPress={e => {
                           if(e.charCode == 13){
                              e.preventDefault();
                              this.savePage();
                           }
                        }}
                        onChange={this.handleChange}
                     />
                  </FormGroup>
               </form>
            </Modal.Body>
            <Modal.Footer>
               <Button onClick={() => this.setState({show : false, value: '', selectedPage: null})}>Close</Button>
               <Button onClick={this.savePage.bind(this)} bsStyle="primary">{(this.state.selectedPage) ? "Save" : "Create"} Page</Button>
            </Modal.Footer>
         </Modal>
      ); 
   }

    editTitle(page){
        this.setState({
            show: true,
            selectedPage: page,
            value: page.title,
        });
    }

	_renderPages(){
      var title = decodeURI(this.props.match.params.name);
      var pages = [];
      for(var x in this.state.contracts){  
         if(this.state.contracts[x].contract_name == title){
            pages = this.state.contracts[x].pages;
         }
      }
      if(pages.length == 0){
         return (
            <h4 style={{textAlign : 'left', fontStyle : 'italic'}}>The current contract has no pages created.</h4>
         );
      }

		return pages.map((x) => {
         return (
            <div style={{
               display : 'flex',
               flexDirection : 'row',
               width : '100%',
               height : '50px',
               marginTop : '5px',
            }}>
               <Button 
               onClick={() => this.props.switchUrl(title, x.title)}
               style={{
                  display : 'flex',
                  flex : 1,
                  marginTop : '10px',
                  textAlign : 'left',
                  paddingLeft : '20px',
                  height : '50px'
               }}>{x ? x.title : ''}</Button>
                
               <Button
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '5px',
                    width: '50px',
                    marginTop: '10px',
                    textAlign: 'left',
                    height: '50px'
                }}
                onClick={this.editTitle.bind(this, x)} >
                    <Glyphicon glyph="pencil" />
                </Button>
               <Button 
               onClick={() => {
                  if (window.confirm(`Delete ${x ? x.title : 'page'}?`)) {
                     fetch(conf.baseURL +`/contracts/${this.state.id}/${x.page_id}/remove`, {
                        method : 'GET',
                        headers : {
                           'Authorization' : 'Bearer ' + window.localStorage.getItem('token'),
                        },
                        credentials : 'include'
                     }).then(r => {
                        return r.json();
                     }).then(r => {
                        return r;
                     }).then(r => {
                        this.refreshContracts();
                     });
                  }
               }}
               style={{
                  display : 'flex', 
                  justifyContent : 'center', 
                  alignItems : 'center',
                  marginLeft : '5px',
                  width : '50px',
                  marginTop : '10px',
                  textAlign : 'left',
                  height : '50px'
               }}><Glyphicon glyph="remove"/></Button>

            </div>
         );
      });
	}


	render(){
      var title = decodeURI(this.props.match.params.name);
		return(
			<div style ={{display: 'flex', flex: 1, justfiyContent: 'center', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll'}}>
            {this._renderModal()}

				<h2 style={{textAlign : 'left', width : '90%'}}>{title}</h2>
				<div style = {{width: '90%', alignItems: 'center'}}>
				{this._renderPages()}
				</div>
				<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end', height : 'min-content', width : '90%', marginTop : '20px'}}>
               <Button onClick={() => this.onClick()} bsStyle="primary">Add Page</Button> 
            </div>
			</div>
		);	
	}
}
