import React, {
   Component
} from 'react';

import{
   ListGroup,
   ListGroupItem,
   Button,
   Glyphicon,
}from 'react-bootstrap';

import Input from './input.js'
export default class List extends Component {
   /* Takes in: 
    *    label
    *    data : an array of JSON objects with format { type : '', label : ''}*/
   constructor(props){
      super(props);
      var d = props.data ? props.data : [];
      this.state = {
         ...props,
         data : d
      }
   }

   componentWillReceiveProps(newProps){
      if(newProps.data){
         this.props = newProps;
         this.setState({
            label : newProps.label,
            data : newProps.data
         });
      }
   }

   _renderItems(){
      return this.state.data.map((x) => {
         return(
               <ListGroupItem style = {{display : 'flex', justifyContent: 'space-around'}}>
                  <Input type = {x.type} placeholder = {x.label}/>
               </ListGroupItem>
         );
      });
   }

   render(){
      return(
            <div style ={{flex:1}}>
               <h4> {this.state.label} </h4>
               <ListGroup style = {{display: 'flex', flexDirection: 'column', margin : '5px'}}>
                  {this._renderItems()}
               </ListGroup>
            </div>
      );
   }
}
