import React, {
   Component
} from 'react';

import { Container } from 'react-container';
import { Switch, Route } from 'react-router-dom';

import Contracts from './contracts';
import Applicants from './applicants';
import Export from './export';
import ExportableFocus from './export/exportable-focus.js'; 
//import Competencies from './competen';
import Email from './email';
import Settings from './settings';

export default class Admin extends Component {
   constructor(props){
      super(props);
      this.state = {
         endpoint : ''
      };
   }

	

	_render(){
      return(
         <Switch>
            <Route path = {'/admin/contracts'} component = {Contracts} />
            <Route path = {'/admin/applicants'} component = {Applicants} />
            <Route path = {'/admin/exportables'} exact component = {Export} />
            <Route path = {'/admin/exportables/:id'} component = {ExportableFocus} />
            {/* <Route path = {'/admin/competencies'} component = {Competencies} /> */}
            <Route path = {'/admin/email'} component={Email} />
            <Route path = {'/admin/settings'} component={Settings} />
         </Switch>
      );
	}

   render(){
		var isMobile = false;
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(navigator.userAgent)){
			isMobile = true;
		}
		var menu = (isMobile) ? 
			[{label: "Applicants", img: require('../../images/applicants.svg')}, {label: 'Contracts', img: require('../../images/contracts.svg')}, {label: 'Exportables', img: require('../../images/exportables.svg')}, {label: 'Competencies', img: require('../../images/competencies.svg')}, {label: "Email"}, {label: "Settings"}] 
			: 
			[{label: "Applicants"}, {label: "Contracts"}, {label: "Exportables"}, {label: "Email"}, {label: "Settings"}]
      return (
         <div style={{display: 'flex', flex: 1}}>
            <Container
               brand={{logo : require('../../images/logo.png')}}
               menu={menu}
            		onMenuSelect={(x) => {
							this.props.history.push(`${this.props.match.url}/${x.label.toLowerCase()}`);
						}}>
						<div style = {{display: 'flex', flex: 1}}>
							{this._render()}
						</div>
				</Container>
         </div>
      )
   }
}
