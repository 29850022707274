import React, { Component } from 'react';
import { Button, ListGroup, ListGroupItem, Modal, Glyphicon } from 'react-bootstrap';
const Add =  require('react-icons/lib/fa/plus');

export default class FList extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         label : 'Foreign List Example',
         listData : [['Example 1', 'Example 1.1'], ['Example 2', 'Example 2.1'], ['Example 3']]
      }
   }

   _renderItem(item){
      var arr = [];
      for(var i = 0; i < item.length; i++){
         arr.push(<div style ={{marginRight : '5px'}}>{item[i]}</div>);
      }
      return arr;
   }
   _renderItems(){
      return this.state.listData.map((x) => {
         return (<ListGroupItem style ={{display : 'flex', justifyContent : 'space-around'}}>
               {this._renderItem(x)}
               <Button bsSize = 'xsmall' onClick={() => {}} style={{position: 'absolute', right: '5px'}}><Glyphicon glyph = 'remove'/></Button>
               </ListGroupItem>);
      });
   }

   render(){
      return (
         <div style = {{flex : 1}}>
         <h4>{this.state.label}</h4>
         <ListGroup style = {{display : 'flex', flexDirection : 'column', margin : '5px'}}>
            {this._renderItems()}
         </ListGroup>
         <div style={{width : '100%', display : 'flex', flexDirection : 'column', justifyContent : 'center'}}>
            <Button style={{width : '65px', alignSelf : 'center'}}>
               <Add /> Add
            </Button>
         </div>
         </div>
      ); 
   }
}
