import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

export default class Input extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         value : undefined,
         initialValue : ''
      };
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.props = newProps;
         this.setState({
            ...newProps
         });
      }
   }

   handleChange(evt){
      this.setState({value: evt.target.value}); 
   }

   parseType(){
      switch(this.state.type){
         case "TEXT":
            return "text";
         case "DATE":
            return "date";
         case "NUMBER":
            return "number";
      }
   }

   parseClass(){
      switch(this.state.type){
         case "TEXTAREA":
            return "textarea";
      }
   }

   render(){
      return (
         <>
         <div style={{fontSize: '1.3em', marginTop: '10px'}}>{this.state.mandatory ? "* " : ""}{this.state.placeholder}</div>
         <FormControl style = {{display: 'flex', margin: '2px', resize: 'none', width: '100%'}}
            type={this.parseType()}
            componentClass={this.parseClass()}
            placeholder={this.state.placeholder}
            value={(this.state.value) ? this.state.value : this.state.initialValue}
            onChange={this.handleChange.bind(this)}/>
         </>
      );
   }
}
