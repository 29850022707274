import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
var util = require('../../utils');

export default class DisplayKeysModal extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props,
         value : [] 
      };
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.setState({
            ...newProps
         });
      }
   }

   render(){
      //Passed in components for us to strip through
      var items = util.flatten(this.state.model);
      var options = [];
      items.map(x => {
         options.push({
            value : x.id,
            label : x.label
         });
      });
      
      return (
         <Modal show={this.state.show}>
            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title">Select Table Display Keys</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Select 
               name = ''
               clearable={false}
               searchable={false}
               multi={true}
               options={options}
               value={this.state.value}
               placeholder="Select display keys for table display..."
               onChange={(v) => {this.setState({value : v})}}         
               />

            </Modal.Body>
            <Modal.Footer>
               <Button onClick={() => this.props.hide()}>Close</Button>
               <Button onClick={() => {
                  //Retrieve display keys
                  var displayKeys = this.state.value.map(x => {
                     return {
                        id : x.value,
                        label : x.label
                     };
                  });
                  this.props.callback(displayKeys);
               }}>Save</Button>
            </Modal.Footer>
         </Modal>
      );
   }
}
