import React, { Component } from "react";
import ProgressBar from "./progress-bar";
import Header from "../../header";
import Select from "react-select";
import ReactMarkdown from "react-markdown";

// import { Form } from 'rrome-react';
import {
  FormControl,
  Glyphicon,
  PanelGroup,
  Panel,
  Col,
  Row,
} from "react-bootstrap";

import Signature from "./signature";
import Exportable from "../../file-download";
import "./index.css";

var { Form } = require("../../rrome-react/index.js");
const upload = require("superagent");
const mime = require("mime-to-extensions");
const conf = require("../../../conf.js");
var Spinner = require("react-spinkit");
const path = require("path");

export default class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      current: 0,
      files: [],
      exportables: [],
      //competencies: [],
      compz: [],
      selected: undefined,
      map: {},
      content: {},
      files: [],
      token:null,
      signature: "",
      submitting: false,
      //competencyPage: {},
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll(e) {
    let scrollTop = window.pageYOffset;
    if (scrollTop > 71) {
      if (!this.state.stickyHeader) {
        this.setState({ stickyHeader: true });
      }
    } else {
      if (this.state.stickyHeader) {
        this.setState({ stickyHeader: false });
      }
    }
  }

  componentWillMount() {
    this.setup();

    

      

  }

  _authenticate(applicant) {
    console.log("posting to " + conf.homeURL + "/p/authenticate/apply");
    return fetch(conf.homeURL + "/p/authenticate/apply", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicant_id: applicant,
      }),
    }).then(r => {
      return r.json();
    });
  }

  _getApplicant(applicant) {
    return fetch(conf.baseURL + `/applicants/${applicant}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(r => {
      return r.json();
    });
  }

  _fetchContract(contract_id) {
    return fetch(conf.baseURL + `/contracts/${contract_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(r => {
      return r.json();
    });
  }

  //TODO: finish this stuff with configurable information panel.
  _fetchContractOptions() {
    console.log("fetching contract options...");
    fetch(conf.baseURL + "/contracts/options", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        console.log("options retrieved...");
        console.log(r);
        this.setState({"contractOptions": r.value});
      });
  }

  // refreshContractOptions() {
  //  console.log("fetching contract options...");
  //  fetch(conf.baseURL + "/contracts/options", {
  //    method: "GET",
  //    headers: {
  //      Authorization: "Bearer " + this.state.token,
  //    },
  //    credentials: "include",
  //  })
  //    .then(r => {
  //      return r.json();
  //    })
  //    .then(r => {
  //      return r;
  //    })
  //    .then(r => {
  //      console.log("options retrieved...");
  //      console.log(r);
  //      return r.value;
  //    });
 //}


  setup() {
    let applicant = this.props.match.params.id;
    this._authenticate(applicant).then(r => {
      this.setState({token:r.token});
      //window.sessionStorage.setItem("token", r.token);
      
        this._getApplicant(applicant).then(r => {
          if (r.status == "Completed") {
            this.props.history.push("/apply/completed");
          }
          this._fetchContract(r.contract_id).then(contract => {
            this.setState({
              applicant: r,
              contract: contract,
            });
            //this.refreshCompetencies();

            this.refreshExportables();
            // this.getCompetencyPage().then(r => {
            //   this.setState({
            //     //competencyPage: { title: r.title, description: r.description },
            //   });
            // });
        
            this._getPartial().then(content => {
              if (content && content.content) {
                this.setState({ content: content.content });
              }
              // if (content && content.competencies) {
              //   this.setState({ map: content.competencies });
        
              //   console.log("MAP", this.state.map);
              // }
              if (content && content.signature) {
                this.setState({ signature: content.signature });
              }
            });
          

          });
          this._fetchContractOptions();
        });
      
    });
  }

  refreshExportables() {
    fetch(conf.baseURL + "/exportables", {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.setState({
          exportables: r,
          files: [],
        });
      });
  }

  // getCompetencyPage() {
  //   return fetch(conf.baseURL + "/competencies/page", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     credentials: "include",
  //   }).then(r => {
  //     return r.json();
  //   });
  // }

  // refreshCompetencies() {
  //   fetch(conf.baseURL + "/competencies", {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "GET",
  //     credentials: "include",
  //   })
  //     .then(r => {
  //       return r.json();
  //     })
  //     .then(r => {
  //       return r;
  //     })
  //     .then(r => {
  //       if (!Object.keys(this.state.map).length > 1) {
  //         var map = {};
  //         for (var i = 0; i < r.length; i++) {
  //           map[r[i].competency_name] = {};
  //         }
  //         this.setState({ compz: r, map: map });
  //       } else {
  //         this.setState({ compz: r });
  //       }
  //     });
  // }

  submitApplication(c) {
    //Don't let them submit the contract unless
    //it's complete
    if (c == null) {
      alert("Please fill out the contract before submitting!");
      return;
    }

    // if (!this.state.signature) {
    //   alert("Please sign competencies before submitting!");
    //   return;
    // }

    this.setState({ submitting: true });
    let formContent = {};

    //Get the competencies
    // var blobs = [];
    // var comp = [];
    // this.state.compz.map(cmp => {
    //   console.log(this.state.map, cmp);
    //   var competencyValues = this.state.map[cmp.competency_name];
    //   if (competencyValues && Object.keys(competencyValues).length > 0) {
    //     var competency = {
    //       name: cmp.competency_name,
    //       items: {},
    //     };
    //     cmp.competency_groups.map(z => {
    //       competency.items[z.competency_name] = {};
    //       var labels = z.competency_labels;
    //       labels.map(j => {
    //         var v = undefined;
    //         if (this.state.map[competency.name][z.competency_name]) {
    //           v = this.state.map[competency.name][z.competency_name][j];
    //         }

    //         if (typeof v === "string") {
    //           competency.items[z.competency_name][j] = v;
    //         } else if (v === null || typeof v === "undefined") {
    //           competency.items[z.competency_name][j] = "";
    //         } else if (v.label !== "undefined") {
    //           competency.items[z.competency_name][j] = v.label;
    //         }
    //       });
    //     });
    //     comp.push(competency);
    //   }
    // });

    // console.log(comp);

    var finish = () => {
      this.setState({ submitting: false });
      fetch(conf.baseURL + "/applicants/status", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        body: JSON.stringify({
          applicant_id: this.state.applicant.applicant_id,
          status: "Completed",
        }),
      })
        .then(e => {
          console.log("e1", e);
          return e.json();
        })
        .then(e => {
          console.log("e2", e);
          return e;
        })
        .then(e => {
          console.log("e3", e);
          this.props.history.push(`/apply/completed`);
        });
    };

    let sub = () => {
      console.log("about to submit...");
      fetch(
        conf.baseURL +
          `/submit?applicantId=${
            this.state.applicant.applicant_id
          }&contractId=${this.state.contract.contract_id}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
          body: JSON.stringify({
            content: c,
            //competencies: comp,
            signature: this.state.signature,
            //competencies_name: this.state.competency_name,
          }),
        }
      )
        .then(r => {
          console.log("r1", r);
          return r.json();
        })
        .then(r => {
          console.log("r2", r);
          return r;
        })
        .then(r => {
          console.log("r3", r);
          if (r.suc) {
            finish();
          } else {
            this.setState({ submitting: false });
            alert(r.message);
          }
        });
    };

    sub();
  }

  _renderIncompleteFormReport() {
    let c = this.state.content;
    console.log("in render incomplete");
    console.log("content:", c);
    console.log("contract:", this.state.contract);
    let contract = this.state.contract;

    let fieldIsValid = field =>
      !field.mandatory || (c && c[field.id] && c[field.id].value);

    return (
      <div style={{ "padding-top": "20px" }}>
        <Row>
          <Col sm={6} smOffset={3}>
            <Panel bsStyle="danger">
              <Panel.Heading>
                <Panel.Title>
                  <Glyphicon glyph="exclamation-sign" />
                  &nbsp;&nbsp; Form Incomplete. Please complete the following
                  fields:
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                {contract
                  ? contract.pages
                      .filter(page =>
                        page.struct.model
                          .flat()
                          .some(field => !fieldIsValid(field))
                      )
                      .map(page => (
                        <div key={page.page_id}>
                          <h3 key={page.page_id}>{page.title}</h3>
                          <ul>
                            {page.struct.model
                              .flat()
                              .filter(field => !fieldIsValid(field))
                              .map(field => (
                                <li key={field.id}>
                                  {field.label}{" "}
                                  {/* <span>{field.id} {field.id.value}</span>
                                <span>{c[field.id] && c[field.id].value ? "OK" : "NOT OK"}</span> */}
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))
                  : ""}
                {/* <h3>Page 1: General Information</h3>
                        <ul>
                           <li>Bank Account Details</li>
                           <li>Travel By Car?</li>
                        </ul>
                        <h3>Page 4: IRD Information</h3>
                        <ul>
                           <li>IRD Number</li>
                           <li>First Name</li>
                        </ul> */}
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }

  _renderSubmit() {
    return (
      <div>
        {this.formCompleted() ? (
          <div
            className="submit-form"
            onClick={() => {
              if (!this.state.submitting) {
                this.submitApplication(this.state.content);
              }
            }}
          >
            Submit Form
            {this.state.submitting ? (
              <Spinner
                color="white"
                style={{ marginLeft: "20px" }}
                name="circle"
              />
            ) : (
              <img
                src={require("../../../images/right-arrow.png")}
                className="submit-icon"
              />
            )}
          </div>
        ) : (
          this._renderIncompleteFormReport()
        )}
      </div>
    );
  }

  setKeyValue(ix, group, name, value) {
    var map = this.state.map;
    var c = map[ix];
    if (!c) c = {};
    var g = c[group];
    if (!g) g = {};
    g[name] = value;
    c[group] = g;
    map[ix] = c;
    this.setState({
      map: map,
    });
    if (this.state.timer) window.clearTimeout(this.state.timer);
    let saveTimer = window.setTimeout(this.savePartial.bind(this), 500);

    console.log(map);
  }

  // _renderCompetencies() {
  //   var isMobile = false;
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     isMobile = true;
  //   }
  //   var jsx = [];
  //   jsx.push(
  //     <h2
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       {this.state.competencyPage.title}
  //     </h2>
  //   );
  //   jsx.push(
  //     <div
  //       style={{
  //         display: "block",
  //         margin: "10px 20px",
  //         textAlign: "left",
  //         fontSize: "1.2em",
  //         whiteSpace: "pre-wrap",
  //       }}
  //     >
  //       <ReactMarkdown source={this.state.competencyPage.description} />
  //     </div>
  //   );

  //   var compentencyGroups = [];
  //   this.state.compz
  //     .filter(x => ["Licences", "Certificates"].includes(x.competency_name))
  //     .map(x => {
  //       var group = [];
  //       x.competency_groups.map(z => {
  //         var labels = z.competency_labels;
  //         var options = z.competency_options.map(o => {
  //           return {
  //             value: o.toLowerCase(),
  //             label: o,
  //           };
  //         });
  //         //Push label in for this competency group
  //         group.push(
  //           <div
  //             style={{
  //               display: "flex",
  //               height: "50px",
  //               textAlign: "center",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               fontSize: "1.5em",
  //               borderBottom: "1px solid #ccc",
  //               marginBottom: "10px",
  //             }}
  //           >
  //             {z.competency_name}
  //           </div>
  //         );

  //         //Map labels to name - dropdown for selection
  //         labels.map(j => {
  //           group.push(
  //             <div
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "row",
  //                 width: "100%",
  //                 margin: "0 0 5px 0",
  //               }}
  //             >
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   flex: 1,
  //                   alignItems: "center",
  //                   paddingLeft: "20px",
  //                   textAlign: "left",
  //                 }}
  //               >
  //                 {j}
  //               </div>
  //               {z.competency_input_type.toLowerCase() == "dropdown" ? (
  //                 <Select
  //                   style={{
  //                     width: isMobile ? "150px" : "300px",
  //                   }}
  //                   options={options}
  //                   clearable={false}
  //                   searchable={false}
  //                   value={
  //                     this.state.map[x.competency_name] &&
  //                     this.state.map[x.competency_name][z.competency_name]
  //                       ? this.state.map[x.competency_name][z.competency_name][
  //                           j
  //                         ]
  //                       : null
  //                   }
  //                   onChange={e =>
  //                     this.setKeyValue(
  //                       x.competency_name,
  //                       z.competency_name,
  //                       j,
  //                       e
  //                     )
  //                   }
  //                 />
  //               ) : (
  //                 <FormControl
  //                   style={{ width: "50%" }}
  //                   type="text"
  //                   value={
  //                     this.state.map[x.competency_name] &&
  //                     this.state.map[x.competency_name][z.competency_name]
  //                       ? this.state.map[x.competency_name][z.competency_name][
  //                           j
  //                         ]
  //                       : ""
  //                   }
  //                   placeholder={z.competency_input_placeholder}
  //                   onChange={e => {
  //                     this.setKeyValue(
  //                       x.competency_name,
  //                       z.competency_name,
  //                       j,
  //                       e.target.value
  //                     );
  //                   }}
  //                 />
  //               )}
  //             </div>
  //           );
  //         });
  //       });
  //       compentencyGroups.push(
  //         <Panel eventKey={x.competency_name}>
  //           <Panel.Heading>
  //             <Panel.Title toggle>
  //               {x.competency_name}
  //               <Glyphicon style={{ float: "right" }} glyph="chevron-down" />
  //             </Panel.Title>
  //           </Panel.Heading>
  //           <Panel.Body collapsible>{group}</Panel.Body>
  //         </Panel>
  //       );
  //     });
  //   compentencyGroups.push(<hr />);
  //   this.state.compz
  //     .filter(x => !["Licences", "Certificates"].includes(x.competency_name))
  //     .map(x => {
  //       var group = [];
  //       x.competency_groups.map(z => {
  //         var labels = z.competency_labels;
  //         var options = z.competency_options.map(o => {
  //           return {
  //             value: o.toLowerCase(),
  //             label: o,
  //           };
  //         });
  //         //Push label in for this competency group
  //         group.push(
  //           <div
  //             style={{
  //               display: "flex",
  //               height: "50px",
  //               textAlign: "center",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               fontSize: "1.5em",
  //               borderBottom: "1px solid #ccc",
  //               marginBottom: "10px",
  //             }}
  //           >
  //             {z.competency_name}
  //           </div>
  //         );

  //         //Map labels to name - dropdown for selection
  //         labels.map(j => {
  //           group.push(
  //             <div
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "row",
  //                 width: "100%",
  //                 margin: "0 0 5px 0",
  //               }}
  //             >
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   flex: 1,
  //                   alignItems: "center",
  //                   paddingLeft: "20px",
  //                   textAlign: "left",
  //                 }}
  //               >
  //                 {j}
  //               </div>
  //               {z.competency_input_type.toLowerCase() == "dropdown" ? (
  //                 <Select
  //                   style={{
  //                     width: isMobile ? "150px" : "300px",
  //                   }}
  //                   options={options}
  //                   clearable={false}
  //                   searchable={false}
  //                   value={
  //                     this.state.map[x.competency_name] &&
  //                     this.state.map[x.competency_name][z.competency_name]
  //                       ? this.state.map[x.competency_name][z.competency_name][
  //                           j
  //                         ]
  //                       : null
  //                   }
  //                   onChange={e =>
  //                     this.setKeyValue(
  //                       x.competency_name,
  //                       z.competency_name,
  //                       j,
  //                       e
  //                     )
  //                   }
  //                 />
  //               ) : (
  //                 <FormControl
  //                   style={{ width: "50%" }}
  //                   type="text"
  //                   value={
  //                     this.state.map[x.competency_name] &&
  //                     this.state.map[x.competency_name][z.competency_name]
  //                       ? this.state.map[x.competency_name][z.competency_name][
  //                           j
  //                         ]
  //                       : ""
  //                   }
  //                   placeholder={z.competency_input_placeholder}
  //                   onChange={e => {
  //                     this.setKeyValue(
  //                       x.competency_name,
  //                       z.competency_name,
  //                       j,
  //                       e.target.value
  //                     );
  //                   }}
  //                 />
  //               )}
  //             </div>
  //           );
  //         });
  //       });
  //       compentencyGroups.push(
  //         <Panel eventKey={x.competency_name}>
  //           <Panel.Heading>
  //             <Panel.Title toggle>
  //               {x.competency_name}
  //               <Glyphicon style={{ float: "right" }} glyph="chevron-down" />
  //             </Panel.Title>
  //           </Panel.Heading>
  //           <Panel.Body collapsible>{group}</Panel.Body>
  //         </Panel>
  //       );
  //     });
  //   jsx.push(
  //     <PanelGroup
  //       accordion
  //       id="comp-accordion"
  //       style={{ width: "90%", margin: "0 auto" }}
  //     >
  //       {compentencyGroups}
  //     </PanelGroup>
  //   );
  //   jsx.push(
  //     <Signature
  //       value={this.state.signature}
  //       onChange={e => {
  //         this.setState({ signature: e });
  //         if (this.state.timer) window.clearTimeout(this.state.timer);
  //         let saveTimer = window.setTimeout(this.savePartial.bind(this), 500);
  //       }}
  //     />
  //   );
  //   return jsx;
  // }

  fillExportable(x) {
    var ext = "pdf";
    var type = "application/pdf";
    var content = this.state.content;
    console.log("Content sent:", content);
    //Post our form content to the server to fill the desired exportable and download it
    //so that the individual can edit it and reupload
    fetch(`/api/fill?exportableId=${x.exportable_id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify({
        content: content,
      }),
    })
      .then(response => {
        console.log(
          "Fetch response for mime type:",
          response.headers.get("Content-Type")
        );
        type = response.headers.get("Content-Type");
        ext = mime.extension(type);
        return response.blob();
      })
      .then(blob => {
        require("js-file-download")(blob, `${x.exportable_name}.${ext}`);
      });
  }

  // _mapCompetencies() {
  //   var comp = [];
  //   this.state.compz.map(cmp => {
  //     if (Object.keys(this.state.map[cmp.competency_name]).length > 0) {
  //       var competency = {
  //         name: cmp.competency_name,
  //         items: {},
  //       };
  //       cmp.competency_groups.map(z => {
  //         competency.items[z.competency_name] = {};
  //         var labels = z.competency_labels;
  //         labels.map(j => {
  //           var v = undefined;
  //           if (this.state.map[competency.name][z.competency_name]) {
  //             v = this.state.map[competency.name][z.competency_name][j];
  //           }

  //           if (typeof v === "string") {
  //             competency.items[z.competency_name][j] = v;
  //           } else if (v === null || typeof v === "undefined") {
  //             competency.items[z.competency_name][j] = "";
  //           } else if (v.label !== "undefined") {
  //             competency.items[z.competency_name][j] = v.label;
  //           }
  //         });
  //       });
  //       comp.push(competency);
  //     }
  //   });
  //   return comp;
  // }

  savePartial() {
    //let competencies = this.state.map;

    let signature = this.state.signature;
    //console.log("Competencies", competencies);
    // this._savePartial(this.state.content, competencies, signature).then(r => {
    //   console.log("Save partial");
    // });

    this._savePartial(this.state.content, signature).then(r => {
      console.log("Save partial");
    });    
  }

  _getPartial() {
    return fetch(
      conf.baseURL + "/track?applicant=" + this.props.match.params.id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        credentials: "include",
      }
    ).then(r => {
      return r
        .json()
        .then(j => j)
        .catch(err => null);
    });
  }

  _savePartial(content, sig) {
    console.log("posting to track...");
    return fetch(conf.baseURL + "/track", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify({
        applicant: this.state.applicant.applicant_id,
        content: content,
        //competencies: competency,
        signature: sig,
      }),
    }).then(r => {
      return r.json();
    });
  }

  handleFormChange(content) {
    console.log("Form change", content);
    if (this.state.timer) window.clearTimeout(this.state.timer);
    let saveTimer = window.setTimeout(this.savePartial.bind(this), 500);
    this.setState({ content: content, timer: saveTimer });
  }

  onDrop = files => {
    //Need to let them upload their completed files
    var f = this.state.files;
    files.map(x => {
      f.push(x);
    });
    console.log("DROPZONE:", f);
    this.setState({ files: f });
  };

  _renderExportablesPage() {
    var contract = this.state.contract;
    if (!contract) return <div />;

    var exports = [];
    var files = [];
    this.state.exportables.map(x => {
      if (contract.contract_id == x.exportable_keys_contract_id) {
        exports.push(
          <Exportable
            key={x.exportable_keys_contract_id}
            file={x}
            content={this.state.content}
          />
        );
      }
    });
    this.state.files.map((x, i) => {
      files.push(<div key={i}>{x.name}</div>);
    });

    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "100%",
          maxWidth: "800px",
          marginTop: "50px",
          marginBottom: "50px",
          background: "#fff",
          border: "1px solid #ccc",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "min-content",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3em",
            padding: "20px",
            cursor: "pointer",
            position: "relative",
            borderBottom: "1px solid #ccc",
          }}
        >
          If you would like a copy of the following forms that you have filled
          out, you may download them below before submitting.
        </div>
        {exports}
      </div>
    );
  }

  // _renderCompetenciesPage() {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignSelf: "center",
  //         width: "100%",
  //         maxWidth: "800px",
  //         marginTop: "50px",
  //         marginBottom: "50px",
  //         background: "#fff",
  //         border: "1px solid #ccc",
  //         flexDirection: "column",
  //       }}
  //     >
  //       {this._renderCompetencies()}
  //     </div>
  //   );
  // }

  _findComponent(page, id) {
    console.log(page, id, this.state.contract);
    let pageStruct = [].concat.apply(
      [],
      this.state.contract.pages[page].struct.model
    );

    for (var i = 0; i < pageStruct.length; i++) {
      if (pageStruct[i].id == id) {
        return pageStruct[i];
      }
    }
  }

  _renderPage(isMobile, numPages, hasExportables, hasCompetencies, struct) {
    var i = this.state.current + 1; //Plus 1 as we check for number, not index
    var jsx = [];
    //If we have both exportables and competencies to render
    if (hasExportables && hasCompetencies) {
      //If we are on the second to last page
      if (i == numPages - 1) jsx.push(this._renderCompetenciesPage());
      //If we are on the last page
      else if (i == numPages) jsx.push(this._renderExportablesPage());
    }

    //If we only have competencies
    if (hasCompetencies && !hasExportables) {
      //On the last page
      if (i == numPages) jsx.push(this._renderCompetenciesPage());
    }

    //If we only have exportables
    if (hasExportables && !hasCompetencies) {
      //On the last page
      if (i == numPages) jsx.push(this._renderExportablesPage());
    }

    //Finally render the actual form, and if we have any JSX to render from the above,
    //render the Form to keep its state saved, but set the display to none so that it is
    //hidden
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {jsx}
        <div
          style={{
            display: jsx.length > 0 ? "none" : "flex",
            alignSelf: "left",
            width: "100%",
            maxWidth: "800px",
            marginTop: isMobile ? "10px" : "50px",
            marginBottom: "50px",
            background: "#fff",
            flexBasis: "auto !important",
            border: "1px solid #ccc",
          }}
          >
          
          {struct ? (
            <Form
              content={this.state.content}
              struct={struct}
              showFooter={false}
              connector={{
                attachToModel: (id, file) => {
                  console.log("id, file", id, file);
                  console.log("file", file);
                  console.log("Uploading file");
                  this.uploadFile(file, (err, res) => {

                    if (err) console.log("Upload error", err);

                    if (res) {
                      console.log("Upload result", res);
                      
                      //Set minio id to minio://id for content
                      let content = this.state.content;
                      let component = this._findComponent(i - 1, id);
                      console.log(component);
                      content[id] = {
                        value: "minio://" + res.id,
                        resmanMapping:
                          component.resmanMapping + path.extname(file.name),
                      };
                      console.log("content[id]", content[id]);
                      this.setState({ content: content });
                      console.log(this.state.content);
                      this.handleFormChange(content);
                    }
                  });
                },
              }}
              onChange={this.handleFormChange.bind(this)}
            />
          ) : (
            <div />
          )}
                  
        </div>
        
      </div>
    );
  }

  uploadFile(file, cb) {
    upload
      .post(conf.baseURL + "/file/upload")
      .withCredentials()
      .set("Authorization", "Bearer " + this.state.token)
      .attach("file", file)
      .end((err, res) => {
        if (err) console.log("Error:", err);
        cb(err, JSON.parse(res.text));
      });
  }

  formCompleted() {
    let contract = this.state.contract;
    let content = this.state.content;
    if (!contract || !content) return false;

    let fieldIsValid = field =>
      !field.mandatory ||
      (content && content[field.id] && content[field.id].value);

    let fields = contract.pages.flatMap(c => c.struct.model).flat();

    return fields.every(fieldIsValid);
  }



  render() {
    var isMobile = false;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(
        navigator.userAgent
      )
    ) {
      isMobile = true;
    }

    var i = this.state.current;
    var struct = undefined;
    var pages = [];
    var num_pages = 0;
    var contract = this.state.contract;
    if (contract) {
      pages = contract.pages;
      num_pages = pages.length;
    }
    //Competencies is second last
    //And the exportables prefilled is last
    var pageTitles = pages.map(x => x.title);
    if (this.state.compz.length > 0) {
      pageTitles.push("Competencies");
      num_pages++;
    }
    if (this.state.exportables.length > 0) {
      pageTitles.push("Final Contracts");
      num_pages++;
    }
    //If we have a struct to assign, assign it
    if (i < pages.length) struct = pages[i].struct;
    var page_title = pageTitles[i];
    var prev_page = i - 1 >= 0 ? pageTitles[i - 1] : "";
    var next_page = i + 1 < pageTitles.length ? pageTitles[i + 1] : "";

    const right = () => {
      if (this.state.current + 1 < num_pages) window.scrollTo(0, 0);
      this.setState({
        current: this.state.current + 1,
      });
    }
  
    const left = () => {
      if (this.state.current - 1 >= 0) window.scrollTo(0, 0);
      this.setState({
        current: this.state.current - 1,
      });
    }

    return (
      this.state.token ?

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: "100vh",
          background: "#f9f9f9",
        }}
      >
        <Header isMobile={isMobile} />

        <ProgressBar
          contractOptions = {this.state.contractOptions}
          titles={pageTitles}
          isMobile={isMobile}
          stickyHeader={this.state.stickyHeader}
          right={right}
          left={left}
          onBubbleClick={this.onBubbleClick.bind(this)}
          showLeft={this.state.current > 0}
          showRight={this.state.current != num_pages - 1}
          max={num_pages}
          current={this.state.current}
          leftPageName={prev_page}
          currentPageName={page_title}
          rightPageName={next_page}
        />
        <div className="main-form-area"
          style={
            this.state.stickyHeader && !isMobile ? { marginTop: "100px" } : {}
          }
        >
          {/*Render submit button*/}
          {num_pages == i + 1 ? this._renderSubmit() : <div />}
          {/*Form or competencies page*/}
          {this._renderPage(
            isMobile,
            num_pages,
            this.state.exportables.length > 0,
            false,
            struct
              ? struct
              : pages.length > 0
              ? pages[pages.length - 1].struct
              : undefined
          )}
        </div>
        <div style={{margin:"auto", marginTop:"-20px"}}>
          <p>
            <span style={{cursor:"pointer"}} hidden={this.state.current === 0} onClick={left}><Glyphicon glyph="chevron-left"></Glyphicon> Previous Page</span>
            <span hidden={this.state.current === 0 || this.state.current === num_pages - 1}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span style={{cursor:"pointer"}} hidden={this.state.current === num_pages - 1} onClick={right}>Next Page <Glyphicon glyph="chevron-right"></Glyphicon></span>
            </p>
          </div>
      </div>
    
            : <div>Authenticating user...</div>
    );
  }

  onBubbleClick(index) {
    window.scrollTo(0, 0);

    this.setState({ current: index });
  }
}
