import React,{
   Component
}from 'react';

import ReactJson from 'react-json-view';
//class for building json objects either for sections or forms
//input 'object' in constructor for desired json to be displayed
//and 'cb' callback function for if form/section is edited
export default class JsonBuilder extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props
      }
   }

   componentWillReceiveProps(newProps){
      if(this.state.props !== newProps){
         this.setState({
            ...newProps
         });
      }
   }

   render(){
      return(
            <ReactJson 
               collapsed={true}
               src = {this.state.object} 
               iconStyle = 'triangle' 
               onEdit = {(this.state.cb) ? this.state.cb : false}
            />
            );
   }   
}
