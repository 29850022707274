import React, { Component } from 'react';
import { Button, Modal, Glyphicon, FormControl} from 'react-bootstrap';
import FSelect from './fselect';
import FList from './flist';
import Input from './input';
import FileChooser from './filechooser';
import Select from 'react-select';
import List from './list';
var util = require('../../../utils');
export default class Section extends Component {
   constructor(props){
      super(props);
      var elements = props.elements ? props.elements : [];
      this.state = {
         ...props,
         components : elements,
         showModal : false,
         editingElement : false,
         editingComponentIndex : -1,
         componentTypes : ['FSELECT', 'FLIST', 'LIST', 'TEXT', 'TEXTAREA', 'DATE', 'NUMBER', 'FILECHOOSER'],
         models : [],
         value : undefined,
         fselectValue : undefined,
         fselectDisplayKeys : [],
         fselectMeta : [],
         fselectDispValue : undefined,
         flistValue : undefined,
         flistDisplayKeys : [],
         flistListDisplay : [],
         flistDispValue : undefined,
         flistListDispValue : undefined,
         listValue : undefined,
         listMeta : [],
         placeholder : undefined
      };
   }
   
   componentWillMount(){
      this.props.getModels().then(r => {
         this.setState({
            models : r
         }); 
      });
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.props = newProps;
         this.setState({
            ...newProps,
            components : newProps.elements,
            componentTypes : ['FSELECT', 'FLIST', 'LIST', 'TEXT', 'TEXTAREA', 'DATE', 'NUMBER', 'FILECHOOSER']
         });
      }
   }
  

   removeElement(componentIndex){
      var components  = this.state.components;
      components.splice(componentIndex, 1);
      this.setState({
         components : components
      });
      this.props.updateElements(componentIndex, this.state.elementIndex, components)
   }

   editFSELECT(elmt){
      var state = {}; 
      var fulldisp = [];
      var disp = [];
      var fv = {value : '', label : ''};
      this.state.models.map(x => {
         if(x._id == elmt["meta-type"].id){
            fv.value = x._id;
            fv.label = x.name;
            fulldisp = util.flatten(x.model);
         }
      });
      fulldisp.forEach(x => {
         if(!x.title)
            disp.push(x);
      });
      var displayKeys = [];
      disp.map(x => {
         elmt["meta-type"].display_keys.map(y => {
            if(x.id == y){
               displayKeys.push({
                  value : x.id,
                  label : x.label
               });
            }
         });
      });

      state.placeholder = elmt.label;
      state.fselectValue = fv;
      state.fselectDisplayKeys  = disp;
      state.fselectDispValue = displayKeys;
      return state;
   }

   editFLIST(elmt){
      var state = {
         value : elmt.type
      };
      var fulldisp = [];
      var disp = [];
      var flv = {value : '', label : ''};
      this.state.models.map(x => {
         if(x._id == elmt["meta-type"].id){
            flv.value = x._id;
            flv.label = x.name;
            fulldisp = util.flatten(x.model);
         }
      });
      fulldisp.forEach(x => {
         if(!x.title)
            disp.push(x);
      });
      var dk = [];
      var ldk = [];
      disp.map(x => {
         elmt["meta-type"].display_keys.map(y => {
            if(x.id == y) dk.push({ value : x.id, label : x.label });
         });
         elmt["meta-type"].list_display.map(y => {
            if(x.id == y) ldk.push({ value : x.id, label : x.label });
         });
      });
      state.flistDisplayKeys = disp;
      state.flistDispValue = dk;
      state.flistListDispValue = ldk;
      state.placeholder = elmt.label;
      state.flistValue = flv;
      return state;
   }

   editLIST(elmt){
      var state = {};
      var vals = {
         TEXT : "Text",
         DATE : "Date",
         TEXTAREA : "Text Area",
         NUMBER : "Number"
      };
      state.placeholder = elmt.label;
      state.listMeta = elmt["meta-type"].map(x => {
         return {
            value : { value : x.type, label : vals[x.type] },
            type : x.type,
            label : x.label
         };
      });
      return state;
   }

   onEditClick(){
      console.log('Current editing element in section:', this.state.components[this.state.editingComponentIndex]);   
      var elmt = this.state.components[this.state.editingComponentIndex];
      var state = {};
      switch(elmt.type.toUpperCase()){
         case "FSELECT":
            state = this.editFSELECT(elmt);   
            break;
         case "FLIST":
            state = this.editFLIST(elmt);   
            break;
         case "LIST":
            state = this.editLIST(elmt);   
            break;
         case "TEXT":
         case "TEXTAREA":
         case "DATE":
         case "NUMBER":
            state.placeholder = elmt.placeholder;
      }
      state.value = { value : elmt.type.toLowerCase(), label : elmt.type };
      this.setState({
         ...state
      });
   }

   _renderPencil(componentIndex){
      return (
         <Glyphicon
         glyph="pencil"
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '17px' }}
         onClick={() => {this.setState({editingElement : true, editingComponentIndex : componentIndex,  showModal : true}, () => {this.onEditClick()})}}></Glyphicon>
      );
   }

   _renderRemove(componentIndex){
      return (
         <Glyphicon
         glyph="remove"
         style={{fontSize : '12px', position : 'absolute', top : '0', right : '0' }}
         onClick={this.removeElement.bind(this, componentIndex)}></Glyphicon>
      );
   }

   _renderComponent(x, componentIndex){
      switch(x.type){
         case "FSELECT":
            return (
               <div style={{position : 'relative', paddingTop : '15px', margin : '5px'}}>
                  {this._renderPencil(componentIndex)}
                  {this._renderRemove(componentIndex)}
                  <FSelect placeholder={x.placeholder}/>
               </div>
            );
         case "FLIST":
            return (
               <div style={{position : 'relative', paddingTop : '15px', margin : '5px'}}>
                  {this._renderPencil(componentIndex)}
                  {this._renderRemove(componentIndex)}
                  <FList />
               </div>
            );
         case "LIST":
            return (
               <div style={{position : 'relative', paddingTop : '15px', margin : '5px'}}>
                  {this._renderPencil(componentIndex)}
                  {this._renderRemove(componentIndex)}
                  <List 
                  label={this.state.components[componentIndex].label} 
                  data={this.state.components[componentIndex]["meta-type"]}/>
               </div>
            );
         case "FILECHOOSER":
            return (
               <div style={{position : 'relative', paddingTop : '15px', margin : '5px'}}>
                  {this._renderPencil(componentIndex)}
                  {this._renderRemove(componentIndex)}
                  <FileChooser title={x.placeholder} style={{paddingTop : '15px'}}/>
               </div>
            );
         case "TEXT":
         case "DATE":
         case "NUMBER":
         case "TEXTAREA":
            return (
               <div style={{position : 'relative', paddingTop : '15px', margin : '5px'}}>
                  {this._renderPencil(componentIndex)}
                  {this._renderRemove(componentIndex)}
                  <Input type={x.type} placeholder={x.placeholder} />
               </div>
            );
      }
   }

   _renderComponents(){
      return this.state.components.map((x, i) => {
         return (
            <div>
               {this._renderComponent(x, i)}
            </div>
         );
      }); 
   }

   _renderSectionSelector(){
      var placeholder = "Select component...";
      var comptypes = this.state.componentTypes;
      var options = comptypes.map(x => {
         return {value : x, label : x}
      }) 
      return (
         <Select 
            name = ''
            clearable={false}
            searchable={false}
            options={options}
            value={this.state.value}
            placeholder={placeholder}
            onChange={(v) => {this.setState({value : v})}}         
         />
      ); 
   }

   _renderAddComponentButton(){
      return (
         <Button 
            bsStyle="info" 
            style={{
               width : '70px',
               alignSelf : 'flex-end',
               marginTop : '5px'
            }}
            onClick={() => {
               this.setState({showModal : true});
            }}>Add...</Button>
      );
   }

   saveSection(){
      var curComp = this.state.components;
      var placeholder = this.state.placeholder;
      var value = this.state.value;
      if(value.value.toUpperCase() == "FSELECT"){
         value.meta = {};
         if(this.state.fselectValue){
            value.meta.id = this.state.fselectValue.value;
            value.meta.display_keys = this.state.fselectDispValue.map(x => {
               return x.value;
            });
         }
         if(!this.state.editingElement){
            curComp.push({
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            });
         }else{
            curComp[this.state.editingComponentIndex] = {
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            };
         }
      }else if(value.value.toUpperCase() == "FLIST"){
         value.meta = {};
         value.meta.id = this.state.flistValue.value;
         value.meta.list_display = this.state.flistListDispValue.map(x => {
            return x.value;
         });
         value.meta.display_keys = this.state.flistDispValue.map(x => {
            return x.value;
         });
         if(!this.state.editingElement){
            curComp.push({
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            });
         }else{
            curComp[this.state.editingComponentIndex] = {
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            };
         }
      }else if(value.value.toUpperCase() == "LIST"){
         value.meta = this.state.listMeta.map(x => {
            return {
               label : x.label,
               type : x.value.value
            };
         }); 
         if(!this.state.editingElement){
            curComp.push({
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            });
         }else{
            curComp[this.state.editingComponentIndex] = {
               type : value.label,
               label : placeholder,
               "meta-type" : value.meta
            }
         }
      }else{
         if(!this.state.editingElement){
            curComp.push({
               type : value.label,
               label : placeholder,
               placeholder : placeholder
            });
         }else{
            curComp[this.state.editingComponentIndex] = {
               type : value.label,
               label : placeholder,
               placeholder : placeholder
            }
         }
      }
      this.setState({
         components : curComp,
         value : undefined,
         placeholder : undefined,
         showModal : false,
         editingElement : false
      });
      this.props.updateElements(this.state.componentIndex, this.state.elementIndex, curComp);
   }

   _renderFSELECTDisplayKeys(){
      var displayKeyOptions = [];
      this.state.fselectDisplayKeys.map(x => {
         displayKeyOptions.push({
            value : x.id,
            label : x.label
         });
      });
      return (
         <Select
         name = ''
         clearable={false}
         searchable={false}
         multi={true}
         options={displayKeyOptions}
         placeholder="Select display keys..."
         value={this.state.fselectDispValue}
         onChange={(e) => {
            console.log('Selected Display Keys', e);
            this.setState({
               fselectDispValue : e
            });
         }}/>
      );
   }
   
   _renderFSELECTInput(){
      var options = this.state.models.map(x => {
         return {
            value : x._id,
            label : x.name
         };
      });
      /* Replace with scheme such as:
       * value : model.id
       * label : model.label
       *
       * Also each model contains the potential meta-types for display purposes*/
      return (
         <div>
            <FormControl 
            style={{margin : '5px 0 5px 0'}} 
            type="text" 
            placeholder="Enter label..." 
            value={this.state.placeholder ? this.state.placeholder : ''} 
            onChange={(e) => {this.setState({placeholder : e.target.value})}}/>
            <Select
            style={{margin : '0px 0 5px 0'}}
            name = ''
            clearable={false}
            searchable={false}
            options={options}
            placeholder="Select type..."
            value={this.state.fselectValue}
            onChange={(e) => {
               var disp = [];
               var fulldisp = [];
               this.state.models.map(x => {
                  if(x._id == e.value){
                     fulldisp = util.flatten(x.model);
                  }
               });
               fulldisp.forEach(x => {
                  if(!x.title)
                     disp.push(x);
               });
               console.log('Editor Modal Display Keys', disp);
               this.setState({
                  fselectValue : e,
                  fselectDisplayKeys : disp
               });
            }}/>
            {this._renderFSELECTDisplayKeys()}
         </div>
      );
   }

   _renderFLISTDisplayKeys(){
      var displayKeyOptions = [];
      this.state.flistDisplayKeys.map(x => {
         displayKeyOptions.push({
            value : x.id,
            label : x.label
         });
      });
      return (
         <div>
         <Select
         style={{margin : '0 0 5px'}}
         name = ''
         clearable={false}
         searchable={false}
         multi={true}
         options={displayKeyOptions}
         placeholder="Select display keys..."
         value={this.state.flistDispValue}
         onChange={(e) => {
            this.setState({
               flistDispValue : e
            });
         }}
         />
         <Select
         name = ''
         clearable={false}
         searchable={false}
         multi={true}
         options={displayKeyOptions}
         placeholder="Select list display ..."
         value={this.state.flistListDispValue}
         onChange={(e) => {
            this.setState({
               flistListDispValue : e
            });
         }}
         />
         </div>
      );
   }

   _renderFLISTInput(){
      var options = this.state.models.map(x => {
         return {
            value : x._id,
            label : x.name
         };
      });
      /* Replace with scheme such as:
       * value : model.id
       * label : model.label
       *
       * Also each model contains the potential meta-types for display purposes*/
      return (
         <div>
            <FormControl 
            style={{margin : '5px 0 5px 0'}} 
            type="text" 
            placeholder="Enter label..." 
            value={this.state.placeholder ? this.state.placeholder : ''} 
            onChange={(e) => {this.setState({placeholder : e.target.value})}}/>
            <Select
            style={{marginBottom : '5px'}}
            name = ''
            clearable={false}
            searchable={false}
            options={options}
            placeholder="Select models..."
            value={this.state.flistValue}
            onChange={(e) => {
            var disp = [];
            var fulldisp = [];
            this.state.models.map(x => {
               if(x._id == e.value){
                  fulldisp = util.flatten(x.model);
               }
            });
            fulldisp.forEach(x => {
               if(!x.title)
                  disp.push(x);
            });
            this.setState({
               flistValue : e,
               flistDisplayKeys : disp
            });
         }}/>
         {this._renderFLISTDisplayKeys()}
         </div>
      );
   }

   _renderLISTInput(){
      var options = [
         {
            value : 'TEXT',
            label : 'Text'
         },
         {
            value : 'DATE',
            label : 'Date'
         },
         {
            value : 'TEXTAREA',
            label : 'Text Area'
         },
         {
            value : 'NUMBER',
            label : 'Number'
         }
      ];


      var jsx = this.state.listMeta.map((x, i) => {
         return (
            <div style={{borderBottom : '1px solid #ccc', marginBottom : '5px'}}>
               <Select 
                  name=''
                  clearable={false}
                  searchable={false}
                  options={options}
                  placeholder="Select input type..."
                  value={x.value}
                  onChange={v => {
                     var meta = this.state.listMeta;
                     meta[i].value = v;
                     meta[i].type = v.label;
                     this.setState({listMeta : meta});
                  }}
               />
               <FormControl 
                  style={{margin : '5px 0 5px 0'}} 
                  type="text" 
                  placeholder="Enter item label..." 
                  value={x.label ? x.label : ''} 
                  onChange={(e) => {
                     var meta = this.state.listMeta;
                     meta[i].label = e.target.value;
                     this.setState({listMeta : meta});
                  }}
               />
            </div>
         );
      });

      return (
         <div>
            <div style={{borderBottom : '1px solid #ccc', marginBottom : '5px'}}>
               <FormControl 
                  style={{margin : '5px 0 5px 0'}}
                  type="text" 
                  placeholder="Enter list label..." 
                  value={this.state.placeholder ? this.state.placeholder : ''} 
                  onChange={(e) => {this.setState({placeholder : e.target.value})}}/>
            </div>
            {jsx}
            <Button 
               style={{margin : '5px'}}
               onClick={() => {
               var meta = this.state.listMeta;
               meta.push({});
               this.setState({listMeta : meta});
               }}>Add</Button>
         </div> 
      );
   }

   _renderInput(){
      if(this.state.value)
         switch(this.state.value.value.toLowerCase()){
            case 'fselect':
               return this._renderFSELECTInput(); 
            case 'flist':
               return this._renderFLISTInput();
            case 'list' :
               return this._renderLISTInput();
            case 'number':
            case 'filechooser':
            case 'text':
            case 'date':
            case 'textarea':
               return (
                  <FormControl 
                     style={{margin : '5px 0 5px 0'}} 
                     type="text" 
                     placeholder="Enter placeholder..." 
                     value={this.state.placeholder ? this.state.placeholder : ''} 
                     onChange={(e) => {this.setState({placeholder : e.target.value})}}/>
               );
         } 
   }

   
   _renderAddElementModal(){
      if(this.state.editingElement){
         
      }
      return (
         <Modal show={this.state.showModal}>
         <Modal.Header>
               <Modal.Title>{this.state.editingElement ? "Edit component..." : "New component..."}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               {this._renderSectionSelector()}
               {this._renderInput()}
            </Modal.Body>

             <Modal.Footer>
                <Button onClick={() => {
                   this.setState({
                      showModal : false,
                      editingElement : false,
                      componentTypes : ['FSELECT', 'FLIST', 'LIST', 'TEXT', 'TEXTAREA', 'DATE', 'NUMBER', 'FILECHOOSER'],
                   }); 
                }}>Close</Button>
                <Button onClick={this.saveSection.bind(this)} bsStyle="primary">Save</Button>
             </Modal.Footer>
          </Modal>
       );
   }

   render(){
      return (
         <div style={{display : 'flex', flex : 1, flexDirection : 'column', border : '1px solid #ccc', margin : '5px', padding : '5px'}}>
            {this._renderComponents()}
            {this._renderAddComponentButton()}
            {this._renderAddElementModal()}
         </div>
      ); 
   }
}  
