import React, {
    Component
} from 'react';
import { Button } from 'react-bootstrap';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/monokai';
var conf = require('../../../conf');

export default class Email extends Component {
    constructor(props){
        super(props);
        this.state = {
            view: 'editor',
            html: '',
            demoName: 'Test Applicant',
            demoLink: 'https://assetrec.online'
        }       
    }

    componentWillMount(){
        this._getTemplate().then((h) => {
            this.setState({html: h.html});
        });
    }
    
    _getTemplate(){
        return fetch(conf.baseURL + "/email/template", {
            method: "GET",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((r) => {
            return r.json();
        });
    }
    
    _updateTemplate(html){
        return fetch(conf.baseURL + "/email/template", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            credentials: 'include',
            body: JSON.stringify({
                html: html
            })
        }).then((r) => {
            return r.json();
        });
    }

    updateTemplate(){
        this._updateTemplate(this.state.html).then((r) => {
            console.log("UPDATE");
        });
    }

    _renderEditor(){
        return (
            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                <div>Interpolate html with &#123;&#123;name}} and &#123;&#123;link}}</div>
                <div style={{display: 'flex', flex: 1}}>
                    <div style={{display: 'flex', flex: 1}}>
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        onChange={(e) => this.setState({html: e})}
                        value={this.state.html}
                        />
                    </div>
                    <div style={{display: 'flex', flex: 1}} dangerouslySetInnerHTML={{__html: this.state.html.replace(/{{name}}/gm, this.state.demoName).replace(/{{link}}/gm, this.state.demoLink)}}>
    
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '15px', paddingBottom: '15px'}}>
                    <Button bsStyle="primary" onClick={this.updateTemplate.bind(this)}>Save</Button>
                </div>
            </div>
        );
    }

    _renderViewer(){
        return null;
    }

    render(){
        if(this.state.view == 'editor'){
            return this._renderEditor();
        }else{
            return this._renderViewer();
        }
    }
}
