import React,{
	Component
} from 'react';

import {Checkbox} from 'react-bootstrap';

export default class exampleCheck extends Component{
	constructor(props){
		super(props);
		this.state={...props}
	}

	componentWillReceiveProps(newProps){
		if(this.props !== newProps){
			this.setState({...newProps});
		}
	}

	render(){
		return(<Checkbox style={{alignSelf:'left'}}>{this.state.label}</Checkbox>);
	}
}
