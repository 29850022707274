import React, { Component } from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Application from './components/application';
import Admin from './components/admin';
import Email from './components/email';
import Login from './components/landing';
import ForgotPassword from './components/forgot-password';
import ResetPassword from './components/reset-password';
import Flat from './components/flat';
import './App.css';



class App extends Component {

  render() {
    return (
       <Router>
         <div className="App">
            <Route path="/login" component={Login} />
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset" component={ResetPassword} />
				<Route path="/apply" component={Application} />
            <Route path="/admin" component={Admin} />
            <Route path="/email" component={Email} />
            <Route exact path="/" component={Flat} />
         </div>
      </Router>
    );
  }
}

export default App;
