import React, { Component } from "react";
import ChevronRight from "react-icons/lib/fa/chevron-right";
import ChevronLeft from "react-icons/lib/fa/chevron-left";
import FaInfoCircle from "react-icons/lib/fa/info-circle";
import ReactMarkdown from "react-markdown";


import { Alert, Button } from "react-bootstrap";

export default class ProgressBar extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      ...props,
      instructionsVisible: true,
      showIndex: -1,
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      this.props = newProps;
      this.setState({
        ...newProps,
        showIndex: -1,
      });
    }
  }

  handleHover(i) {
    this.setState({ showIndex: i });
  }

  handleBubbleClick(index) {
    this.props.onBubbleClick(index);
  }

  _renderPoints() {
    var current = this.state.current;
    var end = this.state.max;
    var jsxPoints = [];
    for (var i = 0; i < end; i++) {
      if (i == current) {
        jsxPoints.push(
          <div
            style={{
              height: "17px",
              width: "17px",
              outlineStyle: "solid",
              outlineWidth: "3px",
              outlineColor: "#fff",
              borderRadius: "0%",
              background: "#e31519",
              zIndex: 1,
            }}
          />
        );
      } else {
        var selected = this.state.showIndex == i;
        jsxPoints.push(
          <div
            onClick={this.handleBubbleClick.bind(this, i)}
            style={{ background: selected ? "#e31519" : "#fff" }}
            onMouseEnter={this.handleHover.bind(this, i)}
            onMouseLeave={() => this.setState({ showIndex: -1 })}
            className="point"
          >
            <div
              style={{
                display: selected ? "block" : "none",
              }}
              className="point-title"
            >
              {this.state.titles[i]}
            </div>
          </div>
        );
      }
    }

    return (
      <div
        style={{
          position: "relative",
          width: `${jsxPoints.length * 25}px`,
          height: "min-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: "5px",
            width: "calc(100% - 10px)",
            position: "absolute",
            margin: "auto",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "#fff",
          }}
        />

        {jsxPoints}
      </div>
    );
  }

  _renderMobile() {
    var style = {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 12,
    };
    var jsx = this.state.stickyHeader ? (
      <div style={{ width: "100%", height: this.state.headerHeight }} />
    ) : (
      <div />
    );
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
        <div
          id="head"
          className="header-progress-bar"
          style={{ paddingBottom: "20px" }}
        >
          <div
            className="header-page-title"
            style={{
              position: "relative",
              marginBottom: "10px",
              fontSize: "1em",
            }}
          >
            {`${this.state.current + 1} - ${this.state.currentPageName}`}
          </div>
          {this._renderPoints()}
        </div>
        {jsx}
        <div
          id="prog"
          className="header-progress"
          style={{
            userSelect: "none",
            overflow: "hidden",
            height: this.props.isMobile ? "60px" : "100px",
            minHeight: "min-content",
            ...style,
          }}
        >
          <div
            onClick={() => {
              if (this.state.showLeft) this.props.left();
            }}
            style={{
              cursor: this.state.showLeft ? "pointer" : "default",
              flex: this.props.isMobile ? 1 : "inherit",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="header-left"
          >
            {this._renderLeft()}
          </div>
          <div
            onClick={() => {
              if (this.state.showRight) this.props.right();
            }}
            style={{
              cursor: this.state.showRight ? "pointer" : "default",
              flex: this.props.isMobile ? 1 : "inherit",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            className="header-right"
          >
            {this._renderRight()}
          </div>
        </div>
        {/* {this._renderInstructions()} */}
      </div>
    );
  }

  handleDismiss = () => {
    this.setState({ instructionsVisible: false });
  };

  handleShowInstructions = () => {
    this.setState({ instructionsVisible: true });
  };

  _renderInstructions() {
    if (this.props.contractOptions) {
      return (
        <div
          style={{
            display: "flex",
            position: "relative",
            flex: "1",
            "justify-content": "center",
            "align-items": "center",
            "flex-direction": "column",
          }}
        >
          {this.props.contractOptions["information-panel-enabled"] &&
            this.state.instructionsVisible ? (
              <Alert bsStyle="info" onDismiss={this.handleDismiss}>
                <h2>
                  <FaInfoCircle /> {this.props.contractOptions["information-panel-title"]}
                </h2>
                <ReactMarkdown source={this.props.contractOptions["information-panel-content"]} />
                <p>
                  <Button bsStyle="link" style={{ align: "center" }} onClick={this.handleDismiss}>
                    Close
                  </Button>
                </p>
              </Alert>
            ) : (
              <Button
                style={{ align: "center" }}
                onClick={this.handleShowInstructions}
                bsStyle="link"
              >
                {this.props.contractOptions["information-panel-link-text"]}
              </Button>
            )} 
        </div>
      );
    }
  }

  _renderDesktop() {
    var headerStyle = {
      display: "flex",
      flexDirection: "column",
      zIndex: 99,
    };

    if (this.state.stickyHeader) {
      headerStyle = Object.assign(
        {},
        {
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
        },
        headerStyle
      );
    }

    return (
      <div style={headerStyle}>
        <div
          className="header-progress"
          style={{
            overflow: "hidden",
            height: this.props.isMobile ? "60px" : "100px",
            minHeight: "min-content",
          }}
        >
          <div
            onClick={() => {
              if (this.state.showLeft) this.props.left();
            }}
            style={{
              cursor: this.state.showLeft ? "pointer" : "default",
              flex: this.props.isMobile ? 1 : "inherit",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="header-left"
          >
            {this._renderLeft()}
          </div>

          <div className="header-progress-bar">
            <div className="header-page-title">
              {`${this.state.current + 1} - ${this.state.currentPageName}`}
            </div>
            {this._renderPoints()}
          </div>

          <div
            onClick={() => {
              if (this.state.showRight) this.props.right();
            }}
            style={{
              cursor: this.state.showRight ? "pointer" : "default",
              flex: this.props.isMobile ? 1 : "inherit",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            className="header-right"
          >
            {this._renderRight()}
          </div>
        </div>
        {/* {this._renderInstructions()} */}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.isMobile ? this._renderMobile() : this._renderDesktop()}
        <div className="main-form-area">
        {this._renderInstructions()}
        </div>
      </div>
    );
  }

  _renderLeft() {
    if (this.state.showLeft) {
      return (
        <div>
          <img
            src={require("../../../../images/left-arrow.png")}
            className="header-left-chevron"
          />
          <div
            className="header-back-text"
            style={{
              marginRight: "10px",
              fontSize: this.props.isMobile ? "0.8em" : "1em",
            }}
          >
            PREVIOUS:
            <br />
            {this.state.leftPageName}
          </div>
        </div>
      );
    }
    return <div />;
  }

  _renderRight() {
    if (this.state.showRight) {
      return (
        <div>
          <img
            src={require("../../../../images/right-arrow.png")}
            className="header-right-chevron"
          />
          <div
            className="header-next-text"
            style={{
              marginLeft: "10px",
              fontSize: this.props.isMobile ? "0.8em" : "1em",
            }}
          >
            NEXT:
            <br />
            {this.state.rightPageName}
          </div>
        </div>
      );
    }
    return <div />;
  }
}
