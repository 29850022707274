import React, {
	Component
} from 'react';

import { Button } from 'react-bootstrap';
import './style.css';
const crypto = require('crypto');
var conf = require('../../conf');

export default class Login extends Component{
	constructor(props){
		super(props);
		this.state={
			...props,
			user: '',
			pass: ''
		}
	}


   onSubmit = e => {
      e.preventDefault();
      const { user, pass } = this.state;
      var hash = crypto.createHash('sha256');
      var pass_hash = hash.update(this.state.pass).digest('hex');
      var login = {
         username : user,
         pass_hash : pass_hash
      };
      fetch(conf.homeURL + '/p/authenticate', {
         method : 'POST',
			credentials: 'include',
         headers : {
            'Content-Type' : 'application/json'
         },
         body : JSON.stringify(login)
      }).then(r => {
         console.log(r);
         return r.json();
      }).then(r => {
         return r;
      }).then(r => {
         if(r.success){
            window.localStorage.setItem('token', r.token);
            this.props.history.push('/admin');
         }else{
            //Failed to login message
            alert(r.messages);
            return;
         }
      });
   } 


	onChange = (e) => {
		const state = this.state;
		state[e.target.name] = e.target.value;
		this.setState(state);
	};

	render() {
		return(
			<div style = {{display: 'flex', width: '100%', height: '100%', justifyContent: 'center'}}> 
				<form style = {{display: 'flex', width: '300px', flexDirection: 'column', alignItems: 'center', marginTop: '15%'}}> 
               <img src={require('../../images/logo.png')} className="login-logo"/>
					<label className="login">
						<input className="login-input login" type="text" name="user" placeholder="Username" value={this.state.user} onChange={this.onChange}/>
					</label>
					<label className="login">
						<input className="login-input login" type="password" name="pass" placeholder="Password" value={this.state.pass} onChange={this.onChange}/>
					</label>
					<Button style={{width: '100%'}}bsStyle = "primary" onClick={this.onSubmit}>Login</Button>
                    <div><a href="/forgot">I forgot my password!</a></div>
				</form>
			</div>
		);
	}
}

