import React, {
    Component
} from 'react';

import { Glyphicon } from 'react-bootstrap';
var Spinner = require('react-spinkit');
var conf = require('../../conf');
var mime = require('mime-to-extensions');

export default class Exportable extends Component {
    constructor(props){
        super(props);
        this.state ={
            ...props,
            loading: false
        }
    }

    componentWillReceiveProps(newProps){
        if(this.props !== newProps){
            this.setState({...newProps});
        }
    }

    onClick(){
        if(!this.state.loading){
            this.setState({loading: true});
            this.download();
        }
    }

    _download(){
        return fetch(conf.baseURL + '/fill?exportableId=' + this.state.file.exportable_id, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                content: this.state.content
            })
        });
    }

    download(){
        var ext = 'pdf';
        var type = 'application/pdf';
        console.log("Download ", this.state.content);        
        this._download().then((r) => {        
            console.log("Downloaded");
            type = r.headers.get('Content-Type');
            ext = mime.extension(type);
            return r.blob();
        }).then((blob) => {
            require("js-file-download")(blob,`${this.state.file.exportable_name}.${ext}`);
            this.setState({loading: false});
        });
    }

    _renderIcon(){
        if(this.state.loading){
            return (
                <Spinner name="line-scale" style={{position: 'absolute', height: '50px', width: '50px', display: 'flex', left: 0, top: 0, justifyContent: 'center', alignItems: 'center'}}/>
            );
        }else{
            return (
                <Glyphicon style={{position: 'absolute', height: '50px', width: '50px', left: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} glyph="download-alt" />
            );
        }
    }

    render(){
        return (
            <div className="exp-download" onClick={this.onClick.bind(this)}>
                {this._renderIcon()}
                {this.state.file.exportable_name}
            </div>
        );
    }
}
