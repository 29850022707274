import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

export default class Paragraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      value: undefined,
      initialValue: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      this.props = newProps;
      this.setState({
        ...newProps,
      });
    }
  }

  render() {
    let count = this.state.paragraph.split(/\r\n|\r|\n/).length;
    return (
      <div
        style={{
          display: "block",
          margin: "2px",
          width: "100%",
          textAlign: "left",
          whiteSpace: "pre-wrap",
        }}
      >
        <ReactMarkdown
          escapeHtml={false}
          source={this.state.paragraph}
          style={{ display: "block" }}
        />
      </div>
    );
  }
}
