import React, {Component} from 'react';
import {Checkbox, FormGroup} from 'react-bootstrap';


export default class rromeCheckbox extends Component {
   constructor(props){
      super(props);
      this.state={
         ...props,
         initialValue: '',
         checked: false
      }
   }

   componentWillReceiveProps(newProps){
      if(this.props !== newProps){
         this.setState({...newProps});
      }
   }

   handleChange(e){
      this.setState({checked : e.target.checked});
      if(this.props.onChange){
         this.props.onChange(e.target.checked);
      }
   }

   render(){
      return(
         <Checkbox checked={this.state.checked} onChange={this.handleChange.bind(this)} style={{display:'flex'}}>{this.state.label}</Checkbox>
      );
   }
}
