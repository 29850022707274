import React, {
   Component
} from 'react';

import './index.css';

export default class Header extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props
      }
   }

   render(){
      var style = {};
      if(this.props.isMobile){
         style.top = 0;
         style.left = 0;
         style.right = 0;
         style.zIndex = 12;
      }
      return (
         <div className="header" style={{position :'relative' , ...style}}>
            <div className="header-image"/>
            {this.props.children}
         </div>
      );
   }
}
