import React, {
	Component
} from 'react';

import Header from '../../header'

export default class Completed extends Component {
	constructor(props){
		super(props);
		this.state={...props}
	}

	render(){
      var isMobile = false;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(navigator.userAgent)) {
         isMobile = true;
      }
      
		return(
			<div style = {{display:'flex', height: '100%', width:'100%', alignItems: 'center', justifyContent : 'center', flexDirection: 'column', background : '#f9f9f9'}}>
            <div style={{display : 'flex', width : '100%', background : '#424447', height : 'min-content', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', padding : '50px'}}>
               <img src={require('../../../images/logo.png')} style = {{display : 'flex', width : '300px', height : 'auto'}}/>
               <div style ={{'text-align': 'center', width: '100%', fontSize: isMobile ? '1.1em' : '1.5em', color : '#f9f9f9', fontFamily : 'Fira Sans, sans-serif', margin : '20px'}}>
               Thank you for completing your application with Asset Recruitment. You'll be hearing from us shortly regarding your application
               </div>
               <div style = {{width: '100%', alignItems: 'center', justifyContent : 'center', display: 'flex'}}>
                  <a href="http://assetrec.co.nz/" style={{fontFamily : 'Fira Sans, sans-serif', fontSize : '1.2em'}}>Our site</a>
               </div>
            </div>
			</div>
		);
	}
}
