/* jshint esversion: 6 */
import React, { Component } from "react";
import {
  Modal,
  Checkbox,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  Glyphicon,
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";

var conf = require("../../../../conf.js");

export default class DisplayContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      show: false,
      editing: false,
      value: "",
      contracts: [],
      pages: [],
      id: "",
      showOptionsModal: false,
    };
  }

  componentWillMount() {
    this.refreshContracts();
    this.refreshContractOptions();
  }

  refreshContractOptions() {
     console.log("fetching contract options...");
    fetch(conf.baseURL + "/contracts/options", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        console.log("options retrieved...");
        console.log(r);
        this.setState({
          "information-panel-title": r.value["information-panel-title"],
          "information-panel-content": r.value["information-panel-content"],
          "information-panel-link-text": r.value["information-panel-link-text"],
          "information-panel-enabled": r.value["information-panel-enabled"],
        });
      });
  }

  refreshContracts() {
    fetch(conf.baseURL + "/contracts", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      credentials: "include",
    })
      .then(r => {
        return r.json();
      })
      .then(r => {
        return r;
      })
      .then(r => {
        console.log(r);
        this.setState({
          contracts: r,
        });
      });
  }

  createContract() {
    fetch(conf.baseURL + "/contracts/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contract_name: this.state.value,
      }),
    })
      .then(r => {
        return r;
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.refreshContracts();
      });
  }

  duplicateContract() {
    console.log("Duplicating contract");
    this._dupe(
      this.state.selectedContract.contract_id,
      this.state.newTitle
    ).then(r => {
      if (r.contract_id) {
        this.setState({ showDuplicate: false, newTitle: "" });
        this.refreshContracts();
      }
    });
  }

  _dupe(contract_id, new_name) {
    return fetch(conf.baseURL + "/contracts/duplicate", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contract: contract_id,
        contractName: new_name,
      }),
    }).then(r => {
      return r.json();
    });
  }

  editContract() {
    fetch(conf.baseURL + "/contracts/edit", {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contract_name: this.state.value,
        contract_id: this.state.id,
        pages: this.state.pages,
      }),
    })
      .then(r => {
        return r;
      })
      .then(r => {
        return r;
      })
      .then(r => {
        this.refreshContracts();
      });
  }

  _renderContracts() {
    if (this.state.contracts.length == 0) {
      return (
        <h4 style={{ textAlign: "left", fontStyle: "italic", width: "100%" }}>
          No current contracts.
        </h4>
      );
    }
    return this.state.contracts.map(x => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "50px",
            marginTop: "5px",
          }}
        >
          <Button
            onClick={() => this.props.switchUrl(x.contract_name)}
            style={{
              display: "flex",
              flex: 1,
              marginTop: "10px",
              textAlign: "left",
              paddingLeft: "20px",
              height: "50px",
              fontSize: "1.6em",
            }}
          >
            {x.contract_name}
          </Button>
          <Button
            onClick={() => {
              this.setState({
                show: true,
                editing: true,
                value: x.contract_name,
                id: x.contract_id,
                pages: x.pages,
              });
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5px",
              width: "50px",
              marginTop: "10px",
              textAlign: "left",
              height: "50px",
            }}
          >
            <Glyphicon glyph="pencil" />
          </Button>
          <Button
            onClick={() => {
              this.setState({ showDuplicate: true, selectedContract: x });
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5px",
              width: "50px",
              marginTop: "10px",
              textAlign: "left",
              height: "50px",
            }}
          >
            <Glyphicon glyph="duplicate" />
          </Button>
          <Button
            onClick={() => {
              if (
                window.confirm(
                  `Delete "${
                    x.contract_name
                  }"?\nThis cannot be undone. Any applicants who have been sent this contract will be unable to complete it if they haven't already.`
                )
              ) {
                fetch(conf.baseURL + `/contracts/${x.contract_id}`, {
                  method: "DELETE",
                  credentials: "include",
                  headers: {
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                })
                  .then(r => {
                    return r.json();
                  })
                  .then(r => {
                    return r;
                  })
                  .then(r => {
                    this.refreshContracts();
                  });
              }
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5px",
              width: "50px",
              marginTop: "10px",
              textAlign: "left",
              height: "50px",
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </div>
      );
    });
  }

  onClick() {
    this.setState({
      show: true,
    });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  saveForm() {
    this.createContract();
    this.setState({
      show: false,
      pages: [],
      id: "",
      value: "",
    });
  }

  editForm() {
    console.log("editing");
    this.editContract();
    this.setState({
      show: false,
      editing: false,
      value: "",
      pages: [],
      id: "",
    });
  }

  _renderDuplicateModal() {
    return (
      <Modal show={this.state.showDuplicate}>
        <Modal.Header>
          <Modal.Title>Duplicate Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup>
              <ControlLabel>Enter new title for contract</ControlLabel>
              <FormControl
                type="text"
                value={this.state.newTitle}
                placeholder="Enter title"
                onChange={e => this.setState({ newTitle: e.target.value })}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              this.setState({
                showDuplicate: false,
                newTitle: "",
              })
            }
          >
            Close
          </Button>
          <Button
            onClick={() => {
              console.log("Duplicating contract @ button");
              this.duplicateContract();
            }}
            bsStyle="primary"
          >
            Duplicate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  saveContractOptions = () => {
    const postBody = JSON.stringify({
      "information-panel-title": this.state["information-panel-title"],
      "information-panel-content": this.state["information-panel-content"],
      "information-panel-link-text": this.state["information-panel-link-text"],
      "information-panel-enabled": this.state["information-panel-enabled"],
    });
    console.log("Options for postback:", postBody);

    fetch(conf.baseURL + "/contracts/options", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include",
      body: postBody,
    }).then(r => this.refreshContractOptions());

    this.setState({ showOptionsModal: false });
  };

  _renderOptionsModal() {
    return (
      <div>
        <Modal show={this.state.showOptionsModal}>
          <Modal.Header>
            <Modal.Title>Edit Contract Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel style={{ marginTop: "5px" }}>
                Information Panel Title
              </ControlLabel>
              <FormControl
                name="information-panel-title"
                value={this.state["information-panel-title"]}
                onChange={evt =>
                  this.setState({ "information-panel-title": evt.target.value })
                }
              />
            </FormGroup>
            <FormGroup style={{ marginTop: "5px" }}>
              <ControlLabel>
                Information Panel Content (Markdown allowed)
              </ControlLabel>
              <FormControl
                componentClass="textarea"
                name="information-panel-content"
                value={this.state["information-panel-content"]}
                onChange={evt =>
                  this.setState({
                    "information-panel-content": evt.target.value,
                  })
                }
              />
              <ReactMarkdown source={this.state["information-panel-content"]} />
            </FormGroup>
            <FormGroup>
              <ControlLabel style={{ marginTop: "5px" }}>
                Information Panel Link Text (text for user to click on to reopen
                information panel)
              </ControlLabel>
              <FormControl
                name="information-panel-link-text"
                value={this.state["information-panel-link-text"]}
                onChange={evt =>
                  this.setState({
                    "information-panel-link-text": evt.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Checkbox
                style={{ marginTop: "5px" }}
                value={this.state["information-panel-enabled"]}
                checked={this.state["information-panel-enabled"]}
                onChange={evt =>
                  this.setState({
                    "information-panel-enabled": evt.target.checked,
                  })
                }
              >
                Enable Information Panel
              </Checkbox>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showOptionsModal: false })}>
              Close
            </Button>
            <Button bsStyle="primary" onClick={this.saveContractOptions}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  _renderModal() {
    return (
      <Modal
        show={this.state.show}
        onKeyPress={e => {
          if (e.charCode == 13 && this.state.show) {
            e.preventDefault();
            this.saveForm();
          }
        }}
      >
        <Modal.Header>
          {this.state.editing ? (
            <Modal.Title>Edit Contract</Modal.Title>
          ) : (
            <Modal.Title>Create new Contract</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup controlId="formBasicText">
              <ControlLabel>Enter new contract title</ControlLabel>
              <FormControl
                type="text"
                value={this.state.value}
                placeholder="Enter text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              this.setState({
                show: false,
                value: "",
                selectValue: {},
                editing: false,
                id: "",
                pages: [],
              })
            }
          >
            Close
          </Button>
          <Button
            onClick={
              this.state.editing
                ? this.editForm.bind(this)
                : this.saveForm.bind(this)
            }
            bsStyle="primary"
          >
            {this.state.editing ? "Save Changes" : "Create Contract"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "90%",
            flexDirection: "column",
            minHeight: "min-content",
          }}
        >
          {this._renderDuplicateModal()}
          {this._renderModal()}
          {this._renderOptionsModal()}
          <div style={{ display: "flex", width: "100%" }}>
            <h2 style={{ textAlign: "left", width: "100%" }}>Contracts</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => this.setState({ showOptionsModal: true })}
            >
              <Glyphicon glyph="cog" style={{ fontSize: "30px" }} />
            </div>
          </div>
          {this._renderContracts()}
          <Button
            onClick={() => this.onClick()}
            bsStyle="primary"
            style={{
              fontSize: "1.2em",
              width: "150px",
              marginTop: "20px",
              alignSelf: "flex-end",
            }}
          >
            Create Contract
          </Button>
        </div>
      </div>
    );
  }
}
