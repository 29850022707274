import React, {Component} from 'react';
import './style.css';

export default class Email extends Component {
   constructor(props){
      super(props);
      this.state = {
         ...props
      };
   }

   render() {
      return (
         <div className="email-container" >
            <div className="email-header">
               <img className="email-logo" src={require('../../images/logo.png')}/>
            </div>
            <div className="email-body">
               <div className="email-body-title">
                  A New Asset Recruitment Application Form Requires Your Completion:
               </div>
               <div className="email-body-text">
                 Please click the button below, complete the form, and submit it 
               </div>
               
               <div className="email-body-button">
                  View Form
               </div>

               <div className="email-body-text">
                  Regards,<br/>
                  The Asset Recruitment team
               </div>
            </div>
            <div className="email-footer" />
         </div>
      );
   }
}
